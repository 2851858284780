
/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
    font-family: 'roboto', sans-serif !important;
    color: #0e0d0d;
    background: #9CC3D5FF !important;
    margin: 0;
    padding: 0;
  }
  
  a {
    color: #030b20;
    text-decoration: none !important;
  }
  
  a:hover {
    color: black;
    text-decoration: none;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Lora', serif;
  }
  
  /*--------------------------------------------------------------
  # Back to top button
  --------------------------------------------------------------*/
  .back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 996;
    background: #0f2b52;
    border: 2px solid #0f2b52;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
  }
  .back-to-top i {
    font-size: 28px;
    color: #fff;
    line-height: 0;
  }
  .back-to-top:hover {
    background: transparent;
    border: 2px solid #0f2b52;
  }
  
  .back-to-top.active {
    visibility: visible;
    opacity: 1;
  }
  
  /*--------------------------------------------------------------
  # Disable aos animation delay on mobile devices
  --------------------------------------------------------------*/
  @media screen and (max-width: 768px) {
    [data-aos-delay] {
      transition-delay: 0 !important;
    }
  }
  /*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
  .header {
    transition: all 0.5s;
    z-index: 997;
    padding: 8px 0;
    background-color: #0d254d;
  }
  .header .logoBack {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
  }
  .header .logoBack::before {
    content: "";
    position: absolute;
    left:0;
    top:0;
    height:100%;
    width:27vw;
    background-color:#E7EDF1;
    z-index: -1;
  }
  .header .logoBack::after {
    content: "";
    position: absolute;
    left:25vw;
    top:-20px;
    height:170px;
    width:140px;
    background-color:#E7EDF1;
    z-index: -1;
    transform: rotate(-20deg);
  }
  .header.header-scrolled {
    padding: 4px 0;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }
  .header .logo {
    font-size: 30px;
    margin: 0;
    padding: 0;
    font-weight: 600;
  }
  .header .logo a {
    color: #ffffff;
  }
  .header .logo img {
    max-height: 50px;
    max-width: 400px;
    border-radius: 5px;
    align-items: calc(-50%);
  }
  hr .hr {
    height: 4px;
    margin-left: 15px;
    margin-bottom:-3px;
  }
  .display-4 {
    font-family: 'Lora', serif !important;
  }
  /*--------------------------------------------------------------
  # Get Started Button
  --------------------------------------------------------------*/
  .header .get-started-btn {
    margin-left: 30px;
    font-family: 'Open Sans', sans-serif;
    color: #0d254d;
    background: #9CC3D5FF;
    border-radius: 50px;
    padding: 10px 25px;
    white-space: nowrap;
    transition: 0.3s;
    font-size: 16px;
    font-weight: 800;
    display: inline-block;
    border: 2px solid #9CC3D5;
  }
  .header .get-started-btn:hover {
    background: transparent;
    color: #9CC3D5FF; 
  }
  @media  (min-width: 992px) {
    .menuToggle {
      display: none !important;
    }
  }
  @media (max-width: 992px) {
    .menuToggle .menuItem {
      display: block !important;
    }
    .header {
      background: #e6edf1;
    }
    .header .get-started-btn {
      margin: 30px;
     padding: 3px 13px;
     font-size: 14px;
     margin-right: 5px;
     margin-left: 0;
     border-radius: 0;
     background: none;
     color: white;
     border: 2px solid #fff;
    }
    .header .con {
      margin-left: 30px;
    }
    .header .get-started-btn:hover {
      background: transparent;
      color: #fff; 
    }
    
    .header .logo img {
      max-height: 38px;
      max-width: 300px;
      border-radius: 5px;
      align-items: calc(-20%);
    }
  }
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /**
  * Desktop Navigation 
  */
  .navbar {
    padding: 0;
  }
  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }
  .navbar li {
    position: relative;
  }
  .navbar a, .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 0px 30px;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 800;
    color: #9CC3D5FF;
    white-space: nowrap;
    transition: 0.3s;
    text-decoration: none;
  }
  .navbar a i, .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }
  .navbar > ul > li > a:before {
    content: "";
    position: absolute;
    width: 80%;
    height: 2px;
    bottom: 0px;
    left: 27px;
    align-items: center;
    background-color: #9CC3D5FF;
    visibility: hidden;
    width: 0px;
    transition: all 0.3s ease-in-out 0s;
  }
  .navbar a:hover:before, .navbar li:hover > a:before, .navbar .active:before {
    visibility: visible;
    width: 70%;
  }
  .navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover > a {
   /*background:  #1fc1c3; */
   color: #9CC3D5FF;
  }
  .navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 30px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    min-width: 260px;
    visibility: hidden;
    background: #9CC3D5FF;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
  }
  .navbar .dropdown ul li {
    min-width: 200px;
  }
  .navbar .dropdown ul a {
    color: #0d254d !important;
    padding: 10px 20px;
    font-size: 14px;
  }
  .navbar .dropdown ul a i {
    font-size: 12px;
  }
  .navbar .dropdown ul a:hover, .navbar .dropdown ul .active:hover, .navbar .dropdown ul li:hover > a {
    font-weight: 800;
    font-size: 16px;
  }
  .navbar .dropdown:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }
  .navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
  }
  .navbar .dropdown .dropdown:hover > ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
  }
  .navbar .mobile-logo {
    visibility: hidden;
  }
  @media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
      left: -90%;
    }
    .navbar .dropdown .dropdown:hover > ul {
      left: -100%;
    }
  }
  /**
  * Mobile Navigation 
  */
  .mobile-nav-toggle {
    color: #0f2b52;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
  }
  .mobile-nav-toggle.bi-x {
    color: #fff;
  }
  @media (max-width: 991px) {
    
    .mobile-nav-toggle {
      display: block;
    }
    .navbar ul {
      display: none;
    }
  }
  .navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    transition: 0.3s;
    z-index: 999;
  }
  .navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  .navbar-mobile ul {
    display: block;
    position: absolute;
    top: 0;
    right: 70px;
    bottom: 0;
    left: 0;
    padding: 60px 0;
    background-color: #0f2b52b2;
    overflow-y: auto;
    transition: 0.3s;
  }
  .navbar-mobile a, .navbar-mobile a:focus {
    padding: 15px 30px;
    font-size: 15px;
    color: #fff;
    text-transform: uppercase;
  }
  .navbar-mobile a:hover:before, .navbar-mobile li:hover > a:before, .navbar-mobile .active:before { 
    visibility: visible;
  }
  .navbar-mobile a:hover, .navbar-mobile .active, .navbar-mobile li:hover > a {
    
   color: #8aacdb;
    
    
  }
  .navbar-mobile .getstarted, .navbar-mobile .getstarted:focus {
    margin: 15px;
  }
  .navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #0f2b52b2;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  }
  .navbar-mobile .dropdown ul li {
    min-width: 200px;
  }
  .navbar-mobile .dropdown ul a {
    color: #fff;
    text-transform: uppercase;
    padding: 10px 20px;
  }
  .navbar-mobile .dropdown a i {
    font-size: 0;
  }
  .navbar-mobile .dropdown ul a:hover, .navbar-mobile .dropdown ul .active:hover, .navbar-mobile .dropdown ul li:hover > a {
    color:  #8aacdb;
  
  }
  .navbar-mobile .dropdown > .dropdown-active {
    display: block;
  }
  /*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
  /*.video-header{
    height: 100vh;
    display: flex;
    align-items: center;
    color: #fff;
  }
  .wrap {
    max-width: 960px;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: auto;
    text-align: center;
  }
  .fullscreen-video-wrap {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    overflow: hidden;
  }
  .fullscreen-video-wrap video {
    min-height: 100%;
    min-width: 100%;
  }
  .header-overlay {
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.815);
    opacity: 0.80;
  }
  .header-content {
    z-index: 2;
    margin-top: 20%;
    padding: 0 5rem;
  }
  .header-content h3 {
    font-size: 50px;
    margin-bottom: 0;
    line-height: 70px;
  }
  .header-content p {
    font-size: 1.5rem;
    display: block;
    padding-bottom: 2rem;
    margin-top: 10px;
  }
  @media(max-width:768px) {
    .header-content h3 {
      font-size: 40px;
      padding-top: 20%;
      line-height: normal;
    }
    .header-content p {
      margin-top: 50px;
      font-size: 1.5rem;
    }
    .video-header{
      height: 50vh;
      display: flex;
      align-items: center;
      color: #fff;
    }
    .wrap {
      max-width: 960px;
      padding-left: 1rem;
      padding-right: 1rem;
      margin: auto;
      text-align: center;
    }
    .fullscreen-video-wrap {
      position: absolute;
      top: 0;
      left: 0;
      height: 50vh;
      width: 50%;
      overflow: hidden;
    }
    .fullscreen-video-wrap video {
      min-height: 100%;
      min-width: 100%;
    }
    .header-overlay {
      height: 50vh;
      width: 100vw;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.815);
      opacity: 0.80;
    }
    
  }
  @media(max-width:400px) {
    .header-content h3 {
      font-size: 20px;
      padding-top: 50%;
      line-height: normal;
    }
    .header-content p {
      margin-top: 50px;
      font-size: 1.5rem;
    }
    .fullscreen-video-wrap video {
      min-height: 50%;
      min-width: 50%;
    }
  }
  #hero {
    width: 100%;
    height: 100vh;
    position: relative;
    padding-top: 82px;
  }
  #hero:before {
    content: "";
    background: rgb(0, 0, 0);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }
  #hero h1 {
    margin: 0;
    font-size: 72px;
    font-weight: 700;
    color: #ffffff;
  }
  #hero h2 {
    color: #ffffff;
    margin: 10px 0 0 0;
    font-size: 24px;
  }*/
  .video-background-holder {
    position: relative;
    background-color: black;
    height: calc(100vh - 72px);
    min-height: 25rem;
    width: 100%;
    overflow: hidden;
  }
  @media (max-width: 768px) {
    .video-background-holder {
      height: calc(90vh);
    }
  }
  
  .video-background-holder video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }
  
  .video-background-content {
    position: relative;
    z-index: 2;
  }
  
  .video-background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.5;
    z-index: 1;
  }
  .video-background-holder .btn-get-started {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 30px;
    border-radius: 50px;
    transition: 0.5s;
    margin-top: 30px;
    color: #ffffff;
    background: #01091c;
    border: 2px solid #ffffff;
  }
  .video-background-holder .btn-get-started:hover {
    background:  none;
  
  }
  /* @media (max-width: 768px) {
    #hero {
      text-align: center;
      padding-top: 58px;
    }
    #hero h1 {
      font-size: 28px;
    }
    #hero h2 {
      font-size: 18px;
      line-height: 24px;
    }
  }
  @media (max-height: 500px) {
    #hero {
      height: 120vh;
    }
  }
  
  /*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
  section {
    padding: 40px 0;
    overflow: hidden;
    position: relative;
  }
  
  .section-title {
    text-align: center;
    padding-bottom: 30px;
    position: relative;
  }
  @media (max-width: 768px) {
    .section-title {
      padding-bottom: 10px;
    }
  }
  .section-title h2 {
    color: #0f2b52;
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
    font-family: 'Lora', serif;
  }
  .section-title h2::after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background: #0f2b52;
    bottom: 0;
    left: calc(50% - 25px);
  }
  .section-title p {
    color: #0f2b52;
    margin-bottom: 0;
    font-size: 19px;
    font-weight: 600;
  }
  
  .section-bg {
    padding: 60px 0;
    color: #f6fcfc;
  }

  @media(max-width:768px) {
    section {
      padding: 10px 0;
    }
    .section-title p {
      font-size: 16.5px;
      text-align: left;
    }
    .section-bg {
      padding: 15px 0;
    }
  }
  /* career on index page  */
  #career-bg {
    padding: 0;
  }
  .career-bg {
    background-size: cover;
    padding: 120px 0;
  }
  @media (max-width: 768px) {
    .career-bg {
      padding: 20px 0;
    }
  }
  .career-bg h1 {
    color: #1fc1c3;
    font-weight: 600;
    font-size: 35px;
  }
  .career-bg .content ul {
    list-style: none;
    padding: 0;
  }
  .career-bg .content ul li + li {
    margin-top: 10px;
  }
  .career-bg .content ul i {
    position: absolute;
    left: 0;
    top: 2px;
    font-size: 20px;
    color: #1fc1c3;
    line-height: 1;
    font-weight: 600;
  }
  .career-front .content p {
    font-weight: 400;
    font-size: 19px;
    color: rgb(235, 222, 222);
  }
  .career-front .content ul li {
    font-weight: 400;
    font-size: 17px;
    color: rgb(231, 222, 222);
  }
  .career-front .content p:last-child {
    margin-bottom: 0;
  }
  .career-front .content .btn-learn-more {
    font-weight: 800;
    font-size: 15px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 50px;
    transition: 0.3s;
    line-height: 1;
    color: #0f2b52;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    margin-top: 6px;
    background: #1fc1c3;
    border: 2px solid #1fc1c3;
    text-decoration: none;
    
  }
  .career-front .content .btn-learn-more:hover {
    border: 2px solid #1fc1c3;
    background: transparent;
    color: #fff;
    text-decoration: none;
  }
  @media (max-width: 768px) {
    .career-front .content p {
      font-size: 17px;
    } 
  }
  /*--------------------------------------------------------------
  # Clients
  --------------------------------------------------------------*/
  .clients {
    padding: 60px 0 60px 0;
    background: #fff;
    top: 0;
    bottom: 0;
  }
  @media (max-width: 768px) {
    .clients {
      padding: 29px 0 19px 0;
    }
  }
  .clients .swiper-pagination {
    margin-top: 60px;
    position: relative;
  }
  .clients .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: rgb(82, 82, 82);
    opacity: 1;
    border: 1px solid white;
  }
  .clients .swiper-pagination .swiper-pagination-bullet-active {
    background-color: black;
  }
  .clients .swiper-slide img {
    height: 50%;
    opacity: 1;
    transition: 0.3s;
   
    
  }
  .clients .swiper-slide img:hover {
    transform: scale(1.2);
    
  }
  /*--------------------------------------------------------------
  # Breadcrumbs
  --------------------------------------------------------------
  
  #breadcrumbs {
    width: 100%;
    height: 80vh;
    position: relative;
    padding-top: 82px;
  }
  #breadcrumbs img {
    background-size: cover;
    background-repeat: no-repeat;
  }
  #breadcrumbs:before {
    content: "";
    background: rgb(0, 0, 0);
    position: absolute;
  }
  #breadcrumbs h1 {
    margin: 0;
    font-size: 72px;
    font-weight: 700;
    color: #ffffff;
  }
  #breadcrumbs h2 {
    color: #ffffff;
    margin: 10px 0 0 0;
    font-size: 24px;
  }
  #breadcrumbs .header-lay {
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.884);
    opacity: 0.80;
  }
  #breadcrumbs .btn-get-started {
  
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 30px;
    border-radius: 50px;
    transition: 0.5s;
    margin-top: 30px;
    color: #ffffff;
    background: #01091c;
    border: 2px solid #ffffff;
  }
  #breadcrumbs .header-content {
    z-index: 2;
    margin-top: 17%;
    padding: 0 5rem;
  }
  #breadcrumbs .header-content h3 {
    font-size: 50px;
    margin-bottom: 0;
    line-height: 70px;
  }
  #breadcrumbs .header-content p {
    font-size: 1.5rem;
    display: block;
    padding-bottom: 2rem;
    margin-top: 10px;
  }
  @media(max-width:768px) {
    #breadcrumbs .header-content h1 {
      margin-top: 50px;
      font-size: 40px;
    }
    #breadcrumbs .header-content p {
      margin-top: 50px;
      font-size: 1.5rem;
    }
  }
  @media(max-width:400px) {
    #breadcrumbs .header-content h1 {
      margin-top: 10px;
      font-size: 40px;
      padding: 0 5rem;
    }
  }
  #hero .btn-get-started:hover {
    background:  black;
    border-color: #ffffff;
  }
  
  
  @media (max-height: 500px) {
    #breadcrumbs {
      height: 120vh;
    }
  }*/ 
  #breadcrumbs {
    width: 100%;
    height: calc(80vh - 110px); 
    background-size: cover;
    position: relative;
  }
  #breadcrumbs .container {
    padding-top: 70px;
  }
  #breadcrumbs:before {
    content: "";
    background: #000000be;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }
  #breadcrumbs h1 {
    margin: 0 0 10px 0;
    font-size: 56px;
    font-weight: 700;
    line-height: 56px;
    font-family: 'Lora', serif;
    color: #fff;
    text-align: center;
  }
  #breadcrumbs .btn-get-started {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 30px;
    border-radius: 50px;
    transition: 0.5s;
    margin-top: 30px;
    color: #ffffff;
    background: #01091c;
    border: 2px solid #ffffff;
  }
  #breadcrumbs .btn-get-started:hover {
    background:  none;
  
  }
  
  @media (min-width: 1024px) {
    #breadcrumbs {
      background-attachment: fixed;
    }
  }
  @media (max-width: 768px) {
    #breadcrumbs {
      text-align: center;
      height: calc(65vh - 110px); 
    }
    
    #breadcrumbs .container {
      padding-top: 60px;
    }
    #breadcrumbs h1 {
      font-size: 35px;
      line-height: 36px;
    }
    
  }
  /*--------------------------------------------------------------
  # breadcrumbs-inner
  --------------------------------------------------------------*/
  .breadcrumbs-inner {
    padding: 60px 0;
    background: #0f2b52;
    min-height: 40px;
    margin-top: 78px;
    color: #fff;
  }
  .breadcrumbs-inner h2 {
    font-size: 28px;
    font-weight: 500;
  }
  @media (max-width: 768px) {
    .breadcrumbs-inner h2 {
      padding-top: 30px;
      font-size: 25px;
    } 
    .breadcrumbs-inner h1 {
      padding-top: 30px;
    }
  }
  .breadcrumbs-inner ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0 0 10px 0;
    margin: 0;
    font-size: 14px;
  }
  .breadcrumbs-inner ol a {
    color: #aaaaaa;
  }
  .breadcrumbs-inner ol a:hover {
    color: #fff;
    transition: 0.3s;
  }
  .breadcrumbs-inner ol li + li {
    padding-left: 10px;
  }
  .breadcrumbs-inner ol li + li::before {
    display: inline-block;
    padding-right: 10px;
    color: #1fc1c3;
    content: "/";
  }
  
  /*--------------------------------------------------------------
  # About
  --------------------------------------------------------------*/
  .about .section-title h2 {
    color: #fff;
    font-weight: 400;
    font-size: 45px;
  }
  .about .section-title h2::after {
    background: #1fc1c3;
  }
  
  @media (max-width: 768px) {
    .about .section-title h2 {
      font-size: 25px;
    }
  }
  .about span {
    color: #1fc1c3;
  }
  .about span:hover {
    color: #fff;
  }
  .about .container {
    max-width: 1300px;
    background: #0f2b52;
  }
  .about .count-box {
    width: 100%;
  }
  .about .count-box i {
    display: block;
    font-size: 48px;
    color: #009961;
    float: left;
    line-height: 0;
  }
  .about .count-box span {
    font-size: 28px;
    line-height: 24px;
    display: block;
    font-weight: 700;
    color: #646c55;
    margin-left: 60px;
  }
  .about .count-box p {
    padding: 5px 0 0 0;
    margin: 0 0 0 60px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: #646c55;
  }
  .about .count-box a {
    font-weight: 600;
    display: block;
    margin-top: 20px;
    color: #646c55;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    transition: ease-in-out 0.3s;
  }
  .about .content h4 {
    text-align: center;
    padding-bottom: 15px;
  }
  /* .about .content .box {
    padding: 20px 15px 5px 15px;
    max-width: 320px;
    margin: 10px;
    background: rgb(166, 192, 221);
    border-radius: 10px;
  }
  .about .count-box a:hover {
    color: #8b9578;
  } */
  .about .content {
    font-size: 15px;
  }
  .about .content h3 {
    font-weight: 400;
    font-size: 24px;
    color: #3c4133;
  }
  .about .content p {
    color: #ffffff;
    font-weight: 400;
    font-size: 18px;
  }
  .about .content ul {
    color: #ffffff;
    font-weight: 400;
    font-size: 18px;
    align-items: center;
    list-style: none;
    padding: 0;
  }
  .about .content ul li {
    padding-bottom: 10px;
    padding-left: 28px;
    position: relative;
  }
  .about .content ul i {
    font-size: 24px;
    color: #0f2b52;
    position: absolute;
    left: 0;
    top: -4px;
  }
  
  .about .content p:last-child {
    margin-bottom: 0;
  }
  
  
  
  @-webkit-keyframes pulsate-btn {
    0% {
      transform: scale(0.6, 0.6);
      opacity: 1;
    }
    100% {
      transform: scale(1, 1);
      opacity: 0;
    }
  }
  
  @keyframes pulsate-btn {
    0% {
      transform: scale(0.6, 0.6);
      opacity: 1;
    }
    100% {
      transform: scale(1, 1);
      opacity: 0;
    }
  }
  .f-name:hover {
    color: #fff;
      }
      
    .partner-container {
        width: 1000px;
        position: relative;
        display: flex;
        justify-content: space-between;
        
    }
    
    .partner-container .card {
        position: relative;
        border-radius: 10px;
        background: transparent;
        border: 2px solid transparent;
        padding: 5px;
        
    }
    
    .partner-container .card .icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #6eadd4;
        border-radius: 20px;
        transition: 0.7s;
        z-index: 1;
    }
    .partner-container .card .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 80px;
        transition: 0.7s;
        color: #fff;
    }
    
    .partner-container h2 {
        position: absolute;
        top: 20%;
        left: 50%;
        text-align: center;
        transform: translate(-50%, -50%);
        font-size: 29px;
        transition: 0.7s;
        color: #0f2b52;
    }
    .partner-container ul {
      position: absolute;
      top: 50%;
      left: 5%;
      text-align: center;
    }
    
    .partner-container .card .face {
        width: 300px;
        height: 185px;
        transition: 0.5s;
    }
    .partner-container .card .face.face1 {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        transform: translateY(100px);
    }
    
    .partner-container .card:hover .face.face1{
        background: transparent;
    } 

    .partner-container .card .face.face1 .content i{
        max-width: 100px;
    }
    
    .partner-container .card .face.face2 {
        position: relative;
        background: rgb(207, 231, 247);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        box-sizing: border-box;
        box-shadow: 0 20px 50px rgba(0,0,0,0.8);
        transform: translateY(-100px);
    }
    
    
    
    .partner-container .card .face.face2 .content p {
        margin: 0;
        padding: 0;
        text-align: center;
        color: #414141;
    }
    
    .partner-container .card .face.face2 .content h3 {
        margin: 0 0 10px 0;
        padding: 0;
        color: #fff;
        font-size: 24px;
        text-align: center;
        color: #414141;
    } 
    
    .partner-container a {
        text-decoration: none;
        color: #414141;
    }
  /* ==========================================
  * CUSTOM UTIL CLASSES
  * ==========================================
  *
  */
  .blockquote-custom {
    position: relative;
    font-size: 1.1rem;
    background:  rgb(217, 221, 224);
  }
  
  .blockquote-custom-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -25px;
    left: 50px;
  }
  .blockquote-footer {
    border-top: 1px solid #0f2b52;
  }
  /*--------------------------------------------------------------
  # Cta
  --------------------------------------------------------------*/
  .cta {
    background: #0f2b52;
    background-size: cover;
    padding: 50px 0;
  }
  .cta h3 {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  .cta p {
    color: #fff;
  }
  .cta .cta-btn {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 35px;
    border-radius: 4px;
    transition: 0.5s;
    margin-top: 20px;
    background: #1fc1c3;
    border: 2px solid #1fc1c3;
    color: #0f2b52;
  }
  .cta .cta-btn:hover {
    background: #0f2b52;
    border: 2px solid #1fc1c3;
    color: #fff;
  }
  .cta-service .cta-btn {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 35px;
    border-radius: 4px;
    transition: 0.5s;
    margin-top: 20px;
    background: #1fc1c3;
    border: 2px solid #1fc1c3;
    color: #0f2b52;
  }
  /*--------------------------------------------------------------
  # Work Process
  --------------------------------------------------------------*/
  .work-process .container {
    max-width: 1300px;
  }
  .serve {
    background: #0f2b52;
    padding: 50px;
    box-shadow: 0px 2px 15px rgba(16, 16, 17, 0.15);
    border-radius: 15px;
  
  }
  .serve .container {
    max-width: 1300px;
  }
  .serve .section-title h2 {
    color: #fff;
    font-weight: 700;
    font-size: 35px;
  }
  .serve .section-title p {
    color: #fff;
    font-size: 21px;
    font-weight: 400;
  }
  .serve .section-title h2::after {
    background: #1fc1c3;
  }
  .work-process .section-title p {
    font-weight: 500;
    font-size: 21px;
  }
  .work-process .content {
    background: #0f2b52;
    color: #f3f4f5;
    margin-top: 40px;
    padding: 50px; 
    border-radius: 9px;
   
  }
  .work-process .content h3 {
    font-weight: 500;
    font-size: 30px;
  }
  
  
  .work-process .content p {
    font-size: 20px;
    font-weight: 400;
  }
  @media (max-width: 768px) {
    .work-process .content p {
      font-size: 16px;
    }
  }
  
  .work-process .content p:last-child {
    margin-bottom: 0;
  }
  .work-process .about1-btn {
    padding: 8px 30px 9px 30px;
    background:  #9CC3D5FF;
    color: #0f2b52;
    border-radius:5px;
    transition: 0.3s;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    display: inline-flex;
    align-items: center;
    border: 2px solid #9CC3D5FF;
  }
  .work-process .about1-btn i {
    font-size: 16px;
    padding-left: 5px;
  }
  .work-process .about1-btn:hover {
    background:  #0f2b52;
    color: #fff;
  }
  @media (max-width: 1200px) {
    .work-process .content h3 {
      text-align: center;
    }
  }
  @media (max-width: 768px) {
    .work-process .content {
      padding: 25px 10px;
    }
    .work-process .content h3 {
      text-align: center;
      font-size: 25px;
    }
    .work-process .learn {
      text-align: center;
    }
    .work-process .about1-btn {
      padding: 8px 10px 8px 15px;
      font-size: 14px;
      border-radius: 0;
    }
    
    
  }
  /*--------------------------------------------------------------
  # Team
  --------------------------------------------------------------*/
  .team {
    background: #9CC3D5FF;
    padding: 60px 0;
  }
  .team .section-title h1::after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background: #fff;
    bottom: 0;
    left: calc(50% - 25px);
  }
  .team .member {
    max-width: 300px;
    margin: 50px;
    text-align: center;
    margin-bottom: 20px;
    background: #343a40;
    position: relative;
    overflow: hidden;
  }
  .team .member .member-info {
    opacity: 0;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    transition: 0.2s;
  }
  .team .member .member-details h4 {
    font-family: 'Caladea', serif;
    font-weight: 600;
    font-size: 30px;
    color: rgb(230, 226, 226);
  }
  .team .member .member-details span {
    display: block;
    font-size: 17px;
    color: rgb(238, 233, 233);
  }
  .team .member .member-info-content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
    transition: bottom 0.4s;
  }
  .team .member .member-info-content h4 {
    font-weight: 700;
    margin-bottom: 2px;
    font-size: 25px;
    color: #fff;
  }
  .team .member .member-info-content span {
    font-style: italic;
    display: block;
    font-size: 19px;
    color: #fff;
  }
  .team .member .social {
    position: absolute;
    left: 0;
    bottom: -38px;
    right: 0;
    height: 48px;
    transition: bottom ease-in-out 0.4s;
    text-align: center;
  }
  .team .member .social a {
    transition: color 0.3s;
    color: rgba(255, 255, 255, 0.7);
    margin: 0 10px;
    display: inline-block;
  }
  .team .member .social a:hover {
    color: #fff;
  }
  .team .member .social i {
    font-size: 20px;
    margin: 0 2px;
  }
  .team .member:hover .member-info {
    background: linear-gradient(0deg, rgba(12, 17, 26, 0.925) 0%, rgba(3, 3, 3, 0) 100%);
    opacity: 1;
    transition: 0.4s;
  }
  .team .member:hover .member-info-content {
    bottom: 60px;
    transition: bottom 0.4s;
  }
  .team .member:hover .social {
    bottom: 0;
    transition: bottom ease-in-out 0.4s;
  }
  
  @media screen and (max-width: 768px) {
    .team {
      padding: 15px 0;
    }
    .team .member {
      margin: 0;
      margin-top: 50px;
    }
  
  }
  
  
  
  
  /*--------------------------------------------------------------
  # Team end
  --------------------------------------------------------------*/
  .events {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.5rem;
  }
  .events img {
    width: 100%;
  }
  .blog-post {
    width: 100%;
    max-width: 70rem;
    max-height: 37rem;
    padding: 5rem;
    background-color: #0f2b52;
    box-shadow: 0 1.4rem 8rem rgba(0,0,0,.2);
    display: flex;
    align-items: center;
    border-radius: .8rem;
  }
  .blog-post_img1 {
    min-width: 30rem;
    max-width: 24rem;
    max-height: 18rem ;
    height: 30rem;
    transform: translateX(-9rem);
    position: relative;
  }
  .blog-post_img1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border-radius: .8rem;
  }
  .blog-post_img1::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  
    box-shadow: .5rem .5rem 3rem 1px black;
    border-radius: .8rem;
  }
  .blog-post_img2 {
    min-width: 30rem;
    max-width: 24rem;
    max-height: 18rem ;
    height: 30rem;
    transform: translateX(9rem);
    position: relative;
  }
  .blog-post_img2 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border-radius: .8rem;
  }
  .blog-post_img2::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
   
    box-shadow: .5rem .5rem 3rem 1px black;
    border-radius: .8rem;
  }
  .blog-post_title {
    font-size: 2.5rem;
    margin: 1.5rem 0 2rem;
    text-transform: uppercase;
    color: #fff;
  }
  .blog-post_para {
    margin-bottom: 3rem;
    font-size: 1.4rem;
    color: rgba(0,0,0,.7);
    color: #fff;
  }
  @media screen and (max-width: 1068px) {
    .blog-post {
      max-width: 80rem;
    }
    .blog-post_img1 {
      min-width: 30rem;
      max-width: 30rem;
    }
  }
  @media screen and (max-width: 868px) {
    .blog-post {
      max-width: 80rem;
    }
    .blog-post_img1 {
      min-width: 30rem;
      max-width: 30rem;
    }
  }
  
  
  
  /*--------------------------------------------------------------
  # about1
  --------------------------------------------------------------*/
  .customBox {
    position: absolute;
    transform: translate(-50%, -130px);
    left: 50%;
    z-index: 10;
  }
  .customHomeBox {
    background-color:#E9EEF4;
    box-shadow: 0px 4px 20px 0px rgba(0,0,0,0.45);
    border-radius: 1em;
  }
  .customHomeBox img {
    transition: 0.25s all ease;
  }
  .customHomeBox .col-2.p-4:not(:nth-child(6)) {
    border-right: 1px solid rgb(218, 218, 218);
  }
  .customHomeBox .triggerBox {
    cursor: pointer;
  }
  .customHomeBox .triggerBox:hover img {
    transform: scale(1.25);
  }
  .customBox .customBoxLabel {
    box-shadow: 0px -4px 6px 0px rgba(0,0,0,0.15);
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    background-color: #E9EEF4 !important;
  }
  .about1 {
    padding: 160px 0 40px 0;
  }
  .about1.wiz {
    background-color: white !important;
    padding: 40px 0 40px 0 !important;
  }
  .about1 .container {
    position: relative;
    z-index: 10;
    max-width: 1170px;
  }
  .about1 .content {
    padding: 10px 30px 10px 0;
  }
  .about1 .content h3 {
    color: #0d254d;
    font-weight: 700;
    font-size: 45px;
    margin-bottom: 30px;
    font-family: 'Lora', serif;
  }
  .about1 .content p {
    font-size: 21px;
    color: #0d254d;
    margin-bottom: 30px;
  }
  @media (max-width: 768px) {
    .about .content p {
      font-size: 14px;
    }
   
  }
  .about1 .content .about1-btn {
    padding: 8px 30px 9px 30px;
    color: #fff;
    border-radius: 5px;
    transition: 0.3s;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    display: inline-flex;
    align-items: center;
    background: #0d254d;
    text-decoration: none;
    border: 2px solid #0f2b52; 
  }
  .about1 .content .about1-btn i {
    font-size: 16px;
    padding-left: 5px;
  }
  .about1 .content .about1-btn:hover {
    background:  none;
    color: #0f2b52;
  }
  .about1 .icon-boxes .icon-box {
    margin-top: 30px;
  }
  .about1 .icon-boxes .icon-box i {
    font-size: 40px;
    color:  #0d254d;
    margin-bottom: 30px;
  }
  .about1 .icon-boxes .icon-box h4 {
    color: #0d254d;
    font-size: 24px;
    font-weight: 700;
    margin: 0 0 10px 0;
  }
  .about1 .icon-boxes .icon-box p {
    font-size: 18px;
    color: #535252;
  }
  
  
  @media (max-width: 1200px) {
    .about1 .content {
      padding-right: 0;
    }
  }
  @media (max-width: 768px) {
    .about1 {
      text-align: center;
    }
    .about1 .content h3 {
      font-size: 32px;
      padding: 0 20px;
    }
    .about1 .content .about1-btn {
      padding: 8px 12px 7px 15px;
      font-size: 12px;
      font-weight: 500;
    }
  }
  .about1 .card {
    border: 0;
    padding: 15px;
    position: relative;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 76% 100%, 25% 100%, 0% 50%);
    
  }
  .about1 .card-body {
    z-index: 10;
    background: #0f2b52;
    padding: 30px 10px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    transition: 0.3s;
    transition: ease-in-out 0.4s;
    border-radius: 5px;
    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 76% 100%, 25% 100%, 0% 50%);
  }
  .about1 .card-title {
    font-weight: 700;
    text-align: center;
    margin-bottom: 15px;
  }
  .about1 .card-title a {
    color: white;
  }
  .about1 .card-body p {
    color: rgb(130, 186, 231);
    text-align: center;
  }
  
  
  .caro {
    height: 100%;
    width: 100%;
    border-radius: 20px;
    align-items: center;
  }
  .caro .text {
    width: 100%;
    display: block;
    text-align: center;
    padding: 20px;
    color: #ffffff;
    
  
  }
  .caro .text h2 {
    margin-bottom: 30px;
    font-size: 25px;
    font-weight: 600;
    padding: 5px;
    text-transform: uppercase;
  }
  .caro .text p {
    font-size: 20px;
    color: #ffffff;
  }@media (max-width: 768px) {
    .caro .text {
      height: 210px;
    }
    .caro .text h2 {
      font-size: 20px;
      margin-bottom: 15px;
    }
    .caro .text p {
      font-size: 15px;
    }
  }
  .single-box {
    position: relative;
    margin-bottom: 30px;
  }
  .carousel-indicators {
    left: 0;
    top: auto;
    bottom: -10px;
  }
  .carousel-indicators li {
    background: #0f2b52;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    padding: 5px;
    margin: 5px;
  }
  .carousel-indicators .active {
    background: blanchedalmond;
  }
  /*--------------------------------------------------------------
  # founder-about
  --------------------------------------------------------------*/
  .founder-about .content h3 {
    font-weight: 700;
    font-size: 26px;
    color: #728394;
  }
  .founder-about .content ul {
    list-style: none;
    padding: 0;
  }
  .founder-about .content ul li {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
  .founder-about .content ul strong {
    margin-right: 10px;
  }
  .founder-about .content ul i {
    font-size: 16px;
    margin-right: 5px;
    color: #0563bb;
    line-height: 0;
  }
  .founder-about .content p:last-child {
    margin-bottom: 0;
  }
  
  /*--------------------------------------------------------------
  # founder
  -----------------------------------------------------------*/
  
  .founder .icon-boxes h3 {
    font-size: 28px;
    font-weight: 700;
    color: #151616;
    margin-bottom: 15px;
  }
  .founder .h5 {
    font-weight: 700;
  }
  .founder .icon-box {
    margin-top: 30px;
  }
  .founder span {
    color: black;
  }
  .founder .icon-box .icon {
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border: 2px solid #0d1d61;
    border-radius: 50px;
    transition: 0.5s;
  }
  .founder .icon-box .icon i {
    color: #0d1d61;
    font-size: 26px;
  }
  .founder .icon-box:hover .icon {
    background: #0d1d61;
    border-color: #0d1d61;
  }
  .founder .icon-box:hover .icon i {
    color: #fff;
  }
  .founder .icon-box .title {
    padding-top: 14px;  
    margin-left: 70px;
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 18px;
    color: #0f2b52;
  }
  .founder .icon-box .title a {
    color: #343a40;
    transition: 0.3s;
  }
  .founder .icon-box .title a:hover {
    color: #0d1d61;
  }
  .founder .icon-box .description {
    margin-left: 85px;
    line-height: 24px;
    font-size: 14px;
  }
  
  
  
  @-webkit-keyframes pulsate-btn {
    0% {
      transform: scale(0.6, 0.6);
      opacity: 1;
    }
    100% {
      transform: scale(1, 1);
      opacity: 0;
    }
  }
  
  @keyframes pulsate-btn {
    0% {
      transform: scale(0.6, 0.6);
      opacity: 1;
    }
    100% {
      transform: scale(1, 1);
      opacity: 0;
    }
  }
  /*--------------------------------------------------------------
  # Resume
  --------------------------------------------------------------*/
  .resume .resume-title {
    font-size: 26px;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #45505b;
  }
  .resume .resume-item {
    padding: 0 0 20px 20px;
    margin-top: -2px;
    border-left: 2px solid black;
    position: relative;
  }
  .resume .resume-item h4 {
    line-height: 18px;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
  
    color: #0d1d61;
    margin-bottom: 10px;
  }
  .resume .resume-item h5 {
    font-size: 16px;
    
    padding: 5px 15px;
    display: inline-block;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .resume .resume-item ul {
    padding-left: 20px;
  }
  .resume .resume-item ul li {
    padding-bottom: 10px;
  }
  .resume .resume-item:last-child {
    padding-bottom: 0;
  }
  .resume .resume-item::before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    left: -9px;
    top: 0;
    background: #fff;
    border: 2px solid black;
  }
  
  
  /*--------------------------------------------------------------
  # Counts
  --------------------------------------------------------------*/
  
  .counts {
    background: linear-gradient(rgba(12, 13, 14, 0.658), rgba(12, 13, 14, 0.644));
   /* background: linear-gradient(rgba(7, 37, 70, 0.8), rgba(8, 49, 90, 0.8)),url("../img/slide/slide-1.jpg") fixed center center; */
    background-size: cover;
    padding: 120px 30px;
  }
  
  .counts .section-title h2 {
    color: #fff;
  }
  .counts .section-title h2::after {
    background: #9CC3D5FF;
  }
  .counts .count-box {
    padding: 30px 30px 25px 30px;
    width: 100%;
    position: relative;
    text-align: center;
    background: rgba(0, 0, 0, 0.658);
    box-shadow: 0px 2px 35px rgba(255, 255, 255, 0.192);
    border-radius: 20px;
  }
  .counts .count-box i {
    position: absolute;
    width: 50px;
    height: 50px;
    top: -27px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 24px;
    background: rgba(0, 0, 0, 0.658);;
    color:  #9CC3D5FF;
    border-radius: 50px;
    border: 2px solid #fff;
    box-shadow: 0px 2px 25px rgba(255, 255, 255, 0.205);
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .counts .count-box i img {
    width: 40px;
    height: 40px;
  }
  .counts .count-box span {
    font-family: 'Merriweather', serif;
    font-size: 45px;
    display: block;
    font-weight: 700;
    color: #9CC3D5FF;
  }
  
  .counts .count-box p {
    padding: 0;
    margin: 0;
     font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: rgb(110, 162, 185);
  }
  @media (max-width:768px) {
    .counts .section-title h2 {
      font-size: 25px;
    }
    .counts {
      padding: 20px 50px;
    }
    .counts .count-box span {
      font-size: 30px;
    }
    .counts .count-box p {
      font-size: 15px;
    }
  }
  /*--------------------------------------------------------------
  
  # Services
  --------------------------------------------------------------*/
  .services .container {
    max-width: 1250px;
  }
  .services .icon-box {
    margin-bottom: 20px;
    padding: 30px;
    border-radius: 6px;
    transition: 0.3;
  }
  .services .icon-box i {
    float: left;
    color:  rgb(60, 229, 252);
    font-size: 40px;
    line-height: 0;
    padding-top: 3px;
   
  }
  .services .icon-box h4 {
    color: #ffffff;
    margin-left: 70px;
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 21px;
    letter-spacing: 0.8px;
    font-family: 'Lora', serif;
  }
  .services .icon-box h4 a {
    
    transition: 0.3s;
  }
  .services .icon-box p {
    font-family: 'Open Sans', sans-serif;
    margin-left: 70px;
    font-weight: 800;
    line-height: 24px;
    font-size: 16px;
  }
  @media screen and (max-width: 768px) {
    .services .icon-box {
      background: #0f2b52!important;
      align-content: center;
      margin-bottom: 0;
      padding: 15px;
    }
    .services .icon-box i {
      color: rgb(60, 229, 252)!important;
      padding-top: 0;
    }
    .services .icon-box h4 {
      color: #ffffff!important;
      padding-top: 7px;
    }
    .services .icon-box p {
      color: #ffffff!important;
      margin: 0;
      margin-top: 20px;
      font-size: 15.5px;
      line-height: 22px;
      font-weight: 500;
      text-align: left!important;
    }
  }
  
  /*--------------------------------------------------------------
  # service-about Us
  --------------------------------------------------------------*/
  
  .service-about .content h3 {
    font-weight: 600;
    font-size: 26px;
  }
  .service-about .content ul {
    list-style: none;
    padding: 0;
  }
  .service-about .content ul li {
    padding-left: 28px;
    position: relative;
  }
  .service-about .content ul li + li {
    margin-top: 10px;
  }
  .service-about .content ul i {
    position: absolute;
    left: 0;
    top: 2px;
    font-size: 20px;
    color: #0f2b52;
    line-height: 1;
    font-weight: 600;
  }
  .service-about .content p {
    font-weight: 600;
    font-size: 19px;
    color: #0f2b52;
  }
  .service-about .content ul li {
    font-weight: 500;
    font-size: 17px;
    color: #0f2b52;
  }
  .service-about .content p:last-child {
    margin-bottom: 0;
  }
  .service-about .content .btn-learn-more {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 50px;
    transition: 0.3s;
    line-height: 1;
    color: #fff;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    margin-top: 6px;
    background: #0f2b52;
    border: 2px solid #0f2b52;
    
  }
  .service-about .content .btn-learn-more:hover {
    border: 2px solid #0f2b52;
    background: transparent;
    color: #0f2b52;
    text-decoration: none;
  }
  @media (max-width: 768px) {
    .service-about .content p {
      font-size: 16.5px;
    }
  }
  /*--------------------------------------------------------------
  # service-about
  --------------------------------------------------------------*/
  .service-about .icon-box {
    padding: 60px 30px;
    transition: all ease-in-out 0.3s;
    background: rgb(204, 219, 236);
    box-shadow: 0px 5px 90px 0px rgba(110, 123, 131, 0.1);
    border-radius: 18px;
    border-bottom: 5px solid #0f2b52;
  }
  
  .service-about .icon-box .icon {
    width: 64px;
    height: 64px;
    background: #0f2b52;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    transition: ease-in-out 0.3s;
    color: #fff;
  }
  .service-about .icon-box .icon i {
    color: #0f2b52;
    font-size: 28px;
  }
  .service-about .icon-box h4 {
    color: #0f2b52;
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 24px;
  }
  .service-about .icon-box .icon i img {
    width: 45px;
    align-items: center;
  }
  .service-about .icon-box h4 a {
    color: #0f2b52;
    transition: ease-in-out 0.3s;
  }
  .service-about .icon-box p {
    color: #0f2b52;
    line-height: 24px;
    font-size: 19px;
    margin-bottom: 0;
  }
  .service-about .icon-box:hover {
    transform: translateY(-10px);
  }
  @media (max-width:768px) {
    .service-about .icon-box {
      padding: 7px 19px;
    }
    .service-about .icon-box .icon {
      margin: 0 auto;
    }
    .service-about .icon-box h4 {
      text-align: center;
    }
    .service-about .icon-box p {
      text-align: center;
    }
  }
  /*--------------------------------------------------------------
  # Features
  --------------------------------------------------------------*/
  .features .card {
    border: 0;
    padding: 90px 15px 15px 15px;
    position: relative;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .features .card-body {
    z-index: 10;
    background: rgba(255, 255, 255, 0.9);
    padding: 5px 10px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    transition: 0.3s;
    transition: ease-in-out 0.4s;
    border-radius: 5px;
  }
  .features .card-title {
    font-weight: 700;
    text-align: center;
    margin-bottom: 15px;
  }
  .features .card-title a {
    color: #3c4133;
  }
  .features .card-text {
    color: #5e5e5e;
  }
  .features .read-more a {
    color: #777777;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    transition: 0.4s;
  }
  .features .read-more a:hover {
    text-decoration: underline;
  }
  .features .card:hover .card-body {
    background: black;
  }
  .features .card:hover .read-more a, .features .card:hover .card-title, .features .card:hover .card-title a, .features .card:hover .card-text {
    color: #fff;
  }
  /*--------------------------------------------------------------
  #  Services
  --------------------------------------------------------------*/
  .featured-services .icon-box {
   /* padding: 60px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 29px 0 rgba(68, 88, 144, 0.12);
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
    z-index: 1; */
   border: 0;
    padding: 40px 0 0 0;
    position: relative;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .featured-services .icon-box::before {
    content: "";
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 100%;
    transition: all 0.3s;
    z-index: -1; 
  
  }
  .featured-services .icon-box:hover::before {
    background: #0f2b52e7;
    top: 0;
    border-radius: 0px;
  }
  .featured-services .icon {
    text-align: center;
    margin-bottom: 5px;
  }
  .featured-services .icon i {
    opacity: 0;
    font-size: 48px;
    line-height: 1;
    color: #1fc1c3;
    transition: all 0.3s ease-in-out;
  }
  .featured-services .title {
    font-weight: 700;
    text-align: center;
    margin-bottom: 5px;
    font-size: 18px;
  }
  .featured-services .title a {
    color: #111;
    opacity: 0;
    
  }
  .featured-services .description {
    opacity: 0;
    padding-top: 5px;
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 15px;
    text-align: center;
  }
  
  
  .featured-services .icon-box:hover .title a {
   
    color: #9CC3D5;
    opacity: 1;
  }
  .featured-services .icon-box:hover .description {
    color: #fff;
    opacity: 1;
  }
  
  .featured-services .icon-box:hover .icon i {
    color: #fff;
    opacity: 1;
  }
  
  
  
  
  
  
  
  .competence .container .card {
    max-width: 300px;
    height: 215px;
    margin: 30px 10px;
    padding: 20px 15px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
    transition: 0.3s ease-in-out
  }
  
  .competence .container .card:hover {
    height: 450px
  }
  
  .competence .container .card .imgContainer {
    position: relative;
    width: 250px;
    height: 250px;
    top: -50px;
    left: 10px;
    z-index: 1;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2)
  }
  
  .competence .container .card .imgContainer img {
    max-width: 100%;
    border-radius: 4px
  }
  
  .competence .container .card .content {
    position: relative;
    margin-top: -140px;
    padding: 10px 15px;
    text-align: center;
    color: #111;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: 0.3s ease-in-out
  }
  
  .competence .container .card:hover .content {
    visibility: visible;
    opacity: 1;
    margin-top: -40px;
    transition-delay: 0.3s
  }
  
  
  
  .core-competence .card-wrapper {
    margin-bottom: 30px;
  }
  .core-competence .card-title {
    text-align: center;
    font-size: 22px;
    color: #dfe5ec;
    font-weight: 500;
  }
  .core-competence .card-text {
    font-size: 19px;
    text-align: center;
    color: #9CC3D5FF;
  }
  .core-competence .card-image .card .card-img-wrapper {
    height: 100%;
  }
  .core-competence .card-image .card .card-body {
    display: none;
  }
  .core-competence .card-image-title-description .card .card-img-wrapper {
    max-height: 160px;
  }
  .core-competence .card-image-title-description .card {
    position: relative;
    min-height: 300px;
  
  }
  .core-competence .card-image-title-description .card .card-body {
    height: auto;
    position: relative;
    top: 0;
    margin-bottom: -70px;
  }
  .core-competence .card-image-title-description .card:hover .card-body {
    top: -70px;
   
  }
  .core-competence .card-image-title-description .card .card-body .card-title {
    margin-bottom: .75rem;
  }
  .core-competence .card {
    display: inline-block;
    position: relative;
    overflow: hidden;
    min-height: 300px;
    height: 100%;
  }
  .core-competence .card:hover {
    box-shadow: 8px 12px 31px -10px #ab98ab;
  }
  .core-competence .card-img-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80%;
    overflow: hidden;
  }
  .core-competence .card-img-wrapper img {
    transition: 0.5s ease;
  }
  .core-competence .card:hover .card-img-wrapper img {
    transform: scale(1.05);
  }
  .core-competence .card-body .card-title {
    margin-bottom: calc(50% + 20px);
    transition: 0.5s ease;
  }
  .core-competence .card:hover .card-body .card-title {
    margin-bottom: .75rem;
  }
  
  .core-competence .card-body {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 30%;
    background-color: #0d244dec;
    transition: 0.5s ease;
  }
  .core-competence .card-content {
    left: 0;
    right: 0;
    overflow: hidden;
    width: 100%;
    height: auto;
    transition: 0.5s ease;
  }
  .core-competence .card:hover .card-body {
    height: 80%;
  }
  .core-competence .card:hover .card-content {
    bottom: 0;
  }
  
  @media (max-width: 330px) {
    .competence .container .card .imgContainer {
        left: -2px
    }
    .core-competence .card-wrapper {
      margin-bottom: 10px;
    }
  }
  
  
  
  
  
  
  
  
  /*--------------------------------------------------------------
  # services-feature
  --------------------------------------------------------------*/
  .services-feature .icon-box {
    text-align: center;
    padding: 40px 20px;
    border-radius: 5px;
    transition: all ease-in-out 0.3s;
    background: #0f2b52;
  }
  .services-feature .icon-box .icon {
    margin: 0 auto;
    width: 64px;
    height: 64px;
    background: transparent;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    transition: ease-in-out 0.3s;
  }
  .services-feature .icon-box .icon i {
    color: #0677A1;
    font-size: 28px;
    transition: ease-in-out 0.3s;
  }
  .services-feature .icon-box h4 {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 24px;
  }
  .services-feature .icon-box h4 a {
    color: #0677A1;
    transition: ease-in-out 0.3s;
  }
  .services-feature .icon-box p {
    color: #ffffff;
    line-height: 24px;
    font-size: 18px;
    margin-bottom: 0;
  }
   
  /* .services-feature .icon-box:hover {
    box-shadow: 0px 0 25px 0 rgba(16, 110, 234, 0.1);
  }
  .services-feature .icon-box:hover h4 a, .services-feature .icon-box:hover .icon i {
    color: #0a253b;
  }
  .services-feature .icon-box:hover .icon {
    border-color: #0a253b;
  } */
  
  /*--------------------------------------------------------------
  # Testimonials
  --------------------------------------------------------------*/
  .testimonials .testimonials-carousel, .testimonials .testimonials-slider {
    overflow: hidden;
  }
  .testimonials .testimonial-item {
    box-sizing: content-box;
    padding: 30px;
    margin: 30px 15px;
    min-height: 200px;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
    position: relative;
    background: #fff;
    border-radius: 15px;
  }
  .testimonials .testimonial-item .testimonial-img {
    width: 90px;
    border-radius: 10px;
    border: 6px solid #fff;
    float: left;
    margin: 0 10px 0 0;
  }
  .testimonials .testimonial-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 25px 0 5px 0;
    color: #111;
  }
  .testimonials .testimonial-item h4 {
    font-size: 14px;
    color: #999;
    margin: 0;
  }
  .testimonials .testimonial-item .quote-icon-left, .testimonials .testimonial-item .quote-icon-right {
    color: #fceaea;
    font-size: 26px;
  }
  .testimonials .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
  }
  .testimonials .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
  }
  .testimonials .testimonial-item p {
    font-style: italic;
    margin: 30px auto 15px auto;
  }
  .testimonials .swiper-pagination {
    margin-top: 20px;
    position: relative;
  }
  .testimonials .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #e03a3c;
  }
  .testimonials .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #e03a3c;
  }
  
  /*--------------------------------------------------------------
  # Blog
  --------------------------------------------------------------*/
  
  .blog .container {
    max-width: 1300px;
    background: #0f2b52;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
  .blog .entry {
    padding: 30px;
    
  }
  .blog .entry .entry-img {
    max-height: 440px;
    margin: -30px -30px 20px -30px;
    overflow: hidden;
  }
  
  .blog .entry .entry-title {
    font-size: 28px;
    font-weight: bold;
    padding: 0;
    margin: 0 0 20px 0;
  } 
  @media (max-width: 768px) {
    .blog .entry .title {
      text-align: center;
      font-size: 25px;
    }
    .blog .entry .entry-title {
      font-size: 20px;
      margin-bottom: 0;
    }
    .blog h3 {
      text-align: center;
    }
  }
  .blog .entry .entry-title a {
    color: #111111;
    transition: 0.3s;
  }
  .blog .entry .entry-title a:hover {
    color: #e03a3c;
  }
  .blog .entry .entry-meta {
    margin-bottom: 15px;
    color: #777777;
  }
  .blog .entry .entry-meta ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    align-items: center;
    padding: 0;
    margin: 0;
  }
  .blog .entry .entry-meta ul li + li {
    padding-left: 20px;
  }
  .blog .entry .entry-meta i {
    font-size: 16px;
    margin-right: 8px;
    line-height: 0;
  }
  .blog .entry .entry-meta a {
    color: #777777;
    font-size: 14px;
    display: inline-block;
    line-height: 1;
  }
  .blog .entry .entry-content p {
    line-height: 29px;
    font-size: 18px;
  }
  .blog .entry .entry-content .read-more {
    -moz-text-align-last: right;
    text-align-last: right;
  }
  .blog .entry .entry-content .read-more a {
    display: inline-block;
    background: #e03a3c;
    color: #fff;
    padding: 6px 20px;
    transition: 0.3s;
    font-size: 14px;
    border-radius: 4px;
  }
  .blog .entry .entry-content .read-more a:hover {
    background: #e35052;
  }
  .blog .entry .entry-content h3 {
    font-size: 22px;
    margin-top: 30px;
    font-weight: bold;
  }
  .blog .entry .entry-content blockquote {
    overflow: hidden;
    background-color: #fafafa;
    padding: 60px;
    position: relative;
    text-align: center;
    margin: 20px 0;
  }
  .blog .entry .entry-content blockquote p {
    color: #444444;
    line-height: 1.6;
    margin-bottom: 0;
    font-style: italic;
    font-weight: 500;
    font-size: 22px;
  }
  .blog .entry .entry-content blockquote::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: #111111;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .blog .entry .entry-footer {
    padding-top: 10px;
    border-top: 1px solid #e6e6e6;
  }
  .blog .entry .entry-footer i {
    color: #5e5e5e;
    display: inline;
  }
  .blog .entry .entry-footer a {
    color: #1e1e1e;
    transition: 0.3s;
  }
  .blog .entry .entry-footer a:hover {
    color: #e03a3c;
  }
  .blog .entry .entry-footer .cats {
    list-style: none;
    display: inline;
    padding: 0 20px 0 0;
    font-size: 14px;
  }
  .blog .entry .entry-footer .cats li {
    display: inline-block;
  }
  .blog .entry .entry-footer .tags {
    list-style: none;
    display: inline;
    padding: 0;
    font-size: 14px;
  }
  .blog .entry .entry-footer .tags li {
    display: inline-block;
  }
  .blog .entry .entry-footer .tags li + li::before {
    padding-right: 6px;
    color: #6c757d;
    content: ",";
  }
  .blog .entry .entry-footer .share {
    font-size: 16px;
  }
  .blog .entry .entry-footer .share i {
    padding-left: 5px;
  }
  .blog .entry-single {
    margin-bottom: 30px;
  }
  .blog .blog-author {
    padding: 20px;
    margin-bottom: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
  .blog .blog-author img {
    width: 120px;
    margin-right: 20px;
  }
  .blog .blog-author h4 {
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 0px;
    padding: 0;
    color: #111111;
  }
  .blog .blog-author .social-links {
    margin: 0 10px 10px 0;
  }
  .blog .blog-author .social-links a {
    color: rgba(17, 17, 17, 0.5);
    margin-right: 5px;
  }
  .blog .blog-author p {
    font-style: italic;
    color: #b7b7b7;
  }
  .blog .blog-comments {
    margin-bottom: 30px;
  }
  .blog .blog-comments .comments-count {
    font-weight: bold;
  }
  .blog .blog-comments .comment {
    margin-top: 30px;
    position: relative;
  }
  .blog .blog-comments .comment .comment-img {
    margin-right: 14px;
  }
  .blog .blog-comments .comment .comment-img img {
    width: 60px;
  }
  .blog .blog-comments .comment h5 {
    font-size: 16px;
    margin-bottom: 2px;
  }
  .blog .blog-comments .comment h5 a {
    font-weight: bold;
    color: #444444;
    transition: 0.3s;
  }
  .blog .blog-comments .comment h5 a:hover {
    color: #e03a3c;
  }
  .blog .blog-comments .comment h5 .reply {
    padding-left: 10px;
    color: #111111;
  }
  .blog .blog-comments .comment h5 .reply i {
    font-size: 20px;
  }
  .blog .blog-comments .comment time {
    display: block;
    font-size: 14px;
    color: #2b2b2b;
    margin-bottom: 5px;
  }
  .blog .blog-comments .comment.comment-reply {
    padding-left: 40px;
  }
  .blog .blog-comments .reply-form {
    margin-top: 30px;
    padding: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
  .blog .blog-comments .reply-form h4 {
    font-weight: bold;
    font-size: 22px;
  }
  .blog .blog-comments .reply-form p {
    font-size: 14px;
  }
  .blog .blog-comments .reply-form input {
    border-radius: 4px;
    padding: 10px 10px;
    font-size: 14px;
  }
  .blog .blog-comments .reply-form input:focus {
    box-shadow: none;
    border-color: #ee9293;
  }
  .blog .blog-comments .reply-form textarea {
    border-radius: 4px;
    padding: 10px 10px;
    font-size: 14px;
  }
  .blog .blog-comments .reply-form textarea:focus {
    box-shadow: none;
    border-color: #ee9293;
  }
  .blog .blog-comments .reply-form .form-group {
    margin-bottom: 25px;
  }
  .blog .blog-comments .reply-form .btn-primary {
    border-radius: 4px;
    padding: 10px 20px;
    border: 0;
    background-color: #111111;
  }
  .blog .blog-comments .reply-form .btn-primary:hover {
    background-color: #1e1e1e;
  }
  .blog .blog-pagination {
    color: #444444;
  }
  .blog .blog-pagination ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .blog .blog-pagination li {
    margin: 0 5px;
    transition: 0.3s;
  }
  .blog .blog-pagination li a {
    color: #111111;
    padding: 7px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .blog .blog-pagination li.active, .blog .blog-pagination li:hover {
    background: #e03a3c;
  }
  .blog .blog-pagination li.active a, .blog .blog-pagination li:hover a {
    color: #fff;
  }
  @media (max-width: 768px) {
    .blog .sidebar {
      margin-top: -50px;
      margin-left: 5px;
    }
    
  }
  .blog .sidebar .sidebar-title {
    font-size: 20px;
    font-weight: 700;
    padding: 0 0 0 0;
    margin: 0 0 15px 0;
    color: #fff;
    position: relative;
  }
  .blog .sidebar .sidebar-item {
    margin-bottom: 30px;
    width: 380px;
    height: 625px;
    overflow-y: scroll;
    
  }
  
  .blog .sidebar .search-form form {
    background: #fff;
    border: 1px solid #ddd;
    padding: 3px 10px;
    position: relative;
  }
  .blog .sidebar .search-form form input[type=text] {
    border: 0;
    padding: 4px;
    border-radius: 4px;
    width: calc(100% - 40px);
  }
  .blog .sidebar .search-form form button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 15px;
    margin: -1px;
    background: #e03a3c;
    color: #fff;
    transition: 0.3s;
    border-radius: 0 4px 4px 0;
    line-height: 0;
  }
  .blog .sidebar .search-form form button i {
    line-height: 0;
  }
  .blog .sidebar .search-form form button:hover {
    background: #e34c4d;
  }
  .blog .sidebar .categories ul {
    list-style: none;
    padding: 0;
  }
  .blog .sidebar .categories ul li + li {
    padding-top: 10px;
  }
  .blog .sidebar .categories ul a {
    color: #111111;
    transition: 0.3s;
  }
  .blog .sidebar .categories ul a:hover {
    color: #e03a3c;
  }
  .blog .sidebar .categories ul a span {
    padding-left: 5px;
    color: #aaaaaa;
    font-size: 14px;
  }
  .blog .sidebar .recent-posts .post-item + .post-item {
    margin-top: 15px;
  }
  .blog .sidebar .recent-posts img {
    width: 100%;
    
  }
  .blog .sidebar .recent-posts h4 {
    font-size: 15px;
    font-weight: bold;
  } 
  .blog .sidebar .recent-posts h4 a {
    color: #111111;
    transition: 0.3s;
  }
  .blog .sidebar .recent-posts h4 a:hover {
    color: #e03a3c;
  }
  .blog .sidebar .recent-posts time {
    display: block;
    font-style: italic;
    font-size: 14px;
    color: #aaaaaa;
  }
  .blog .sidebar .tags {
    margin-bottom: -10px;
  }
  .blog .sidebar .tags ul {
    list-style: none;
    padding: 0;
  }
  .blog .sidebar .tags ul li {
    display: inline-block;
  }
  .blog .sidebar .tags ul a {
    color: #515151;
    font-size: 14px;
    padding: 6px 14px;
    margin: 0 6px 8px 0;
    border: 1px solid #c4c4c4;
    display: inline-block;
    transition: 0.3s;
  }
  .blog .sidebar .tags ul a:hover {
    color: #fff;
    border: 1px solid #e03a3c;
    background: #e03a3c;
  }
  .blog .sidebar .tags ul a span {
    padding-left: 5px;
    color: #aaaaaa;
    font-size: 14px;
  }
  .blog .sidebar1 {
    padding: 0 10px;
    margin: 0 0 39px 0;
    
  }
  .blog .sidebar1 .sidebar-title {
    font-size: 20px;
    font-weight: 700;
    padding: 0 0 0 0;
    margin: 0 0 15px 0;
    color: #fff;
    position: relative;
  }
  .blog .sidebar1 .sidebar-item {
    margin-bottom: 30px;
  }
  .blog .sidebar1 .search-form form {
    background: #fff;
    border: 1px solid #ddd;
    padding: 3px 10px;
    position: relative;
  }
  .blog .sidebar1 .search-form form input[type=text] {
    border: 0;
    padding: 4px;
    border-radius: 4px;
    width: calc(100% - 40px);
  }
  .blog .sidebar1 .search-form form button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 15px;
    margin: -1px;
    background: #e03a3c;
    color: #fff;
    transition: 0.3s;
    border-radius: 0 4px 4px 0;
    line-height: 0;
  }
  .blog .sidebar1 .search-form form button i {
    line-height: 0;
  }
  .blog .sidebar .search-form form button:hover {
    background: #e34c4d;
  }
  .blog .sidebar1 .categories ul {
    list-style: none;
    padding: 0;
  }
  .blog .sidebar1 .categories ul li + li {
    padding-top: 10px;
  }
  .blog .sidebar1 .categories ul a {
    color: #111111;
    transition: 0.3s;
  }
  .blog .sidebar1 .categories ul a:hover {
    color: #e03a3c;
  }
  .blog .sidebar1 .categories ul a span {
    padding-left: 5px;
    color: #aaaaaa;
    font-size: 14px;
  }
  .blog .sidebar1 .recent-posts .post-item + .post-item {
    margin-top: 15px;
  }
  .blog .sidebar1 .recent-posts img {
    width: 100%;
    
  }
  .blog .sidebar1 .recent-posts h4 {
    font-size: 15px;
    font-weight: bold;
  } 
  .blog .sidebar1 .recent-posts h4 a {
    color: #111111;
    transition: 0.3s;
  }
  .blog .sidebar1 .recent-posts h4 a:hover {
    color: #e03a3c;
  }
  .blog .sidebar1 .recent-posts time {
    display: block;
    font-style: italic;
    font-size: 14px;
    color: #aaaaaa;
  }
  .blog .sidebar1 .tags {
    margin-bottom: -10px;
  }
  .blog .sidebar1 .tags ul {
    list-style: none;
    padding: 0;
  }
  .blog .sidebar1 .tags ul li {
    display: inline-block;
  }
  .blog .sidebar1 .tags ul a {
    color: #515151;
    font-size: 14px;
    padding: 6px 14px;
    margin: 0 6px 8px 0;
    border: 1px solid #c4c4c4;
    display: inline-block;
    transition: 0.3s;
  }
  .blog .sidebar1 .tags ul a:hover {
    color: #fff;
    border: 1px solid #e03a3c;
    background: #e03a3c;
  }
  .blog .sidebar1 .tags ul a span {
    padding-left: 5px;
    color: #aaaaaa;
    font-size: 14px;
  }
  .blog .title {
    color: #fff;
    font-weight: 700;
  }
  
  #cards_landscape_wrap-2{
  text-align: center;
  background: #F7F7F7;
  }
  #cards_landscape_wrap-2 .container{
  padding-top: 80px; 
  padding-bottom: 100px;
  }
  #cards_landscape_wrap-2 a{
  text-decoration: none;
  outline: none;
  }
  .entry .card-flyer {
  border-radius: 5px;
  }
  .entry .card-flyer .image-box{
  background: #ffffff;
  overflow: hidden;
  border-radius: 5px;
  }
  .entry .card-flyer .image-box img{
  width: 100%;
  transition:all .9s ease; 
  -moz-transition:all .9s ease; 
  -o-transition:all .9s ease;
  -ms-transition:all .9s ease; 
  height: 100%;
  
  }
  .entry .card-flyer:hover .image-box img{
  
  -webkit-transform:scale(1.02);
  -moz-transform:scale(1.02);
  -ms-transform:scale(1.02);
  -o-transform:scale(1.02);
  transform:scale(1.02);
  }
  .entry .card-flyer .text-box{
  text-align: center;
  }
  .entry .card-flyer .text-box .text-container{
  padding: 30px 18px;
  }
  .entry .card-flyer{
  background: #FFFFFF;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.40);
  }
  .entry .card-flyer:hover{
  background: #fff;
  box-shadow: 0px 15px 26px rgba(0, 0, 0, 0.50);
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  }
  .entry .card-flyer .text-box p{
  margin-top: 10px;
  padding-top: 20px;
  margin-bottom: 40px;
  padding-bottom: 10px; 
  font-size: 14px;
  letter-spacing: 1px;
  color: #000000;
  }
  @media (max-width: 768px) {
    .entry .card-flyer .text-box p {
      margin: 0;
      padding: 0;
    } 
  }
  .entry .card-flyer .text-box h6{
  margin-top: 0px;
  margin-bottom: 4px; 
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'Roboto Black', sans-serif;
  letter-spacing: 1px;
  color: #00acc1;
  }
  
  
  
  
  .portfolio-item .portfolio-info {
  opacity: 0;
  }
  .portfolio-item:hover .portfolio-info {
  opacity: 1;
  }
  .portfolio-info {
  background: rgba(17, 17, 17, 0.404);
  
  }
  .sidebar .portfolio-item {
  margin-bottom: 30px;
  padding-right: 20px;
  }
  .sidebar #portfolio-flters {
  padding: 0;
  margin: 0 auto 20px auto;
  list-style: none;
  text-align: center;
  }
  .sidebar #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 8px 15px 10px 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: #444444;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
  border-radius: 3px;
  }
  .portfolio #portfolio-flters li:hover, .portfolio #portfolio-flters li.filter-active {
  color: #fff;
  background: #e03a3c;
  }
  
  
  
  
  
  .sidebar #portfolio-flters li:last-child {
  margin-right: 0;
  }
  .sidebar .portfolio-wrap {
  transition: 0.3s;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  z-index: 1;
  height: 200px;
  background: rgba(17, 17, 17, 0.6);
  border: 2px solid #fff;
  }
  .sidebar .portfolio-wrap::before {
  content: "";
  background: rgba(4, 26, 59, 0.6);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
  }
  .sidebar .portfolio-wrap img {
  transition: all ease-in-out 0.3s;
  }
  .sidebar .portfolio-wrap .portfolio-info {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 70px;
  }
  @media (max-width: 768px) {
    .sidebar .portfolio-wrap .portfolio-info {
      padding: 50px 0 ;
    }
  }
  .sidebar .portfolio-wrap .portfolio-info h4 {
  font-size: 20px;
  color: rgb(255, 255, 255);
  font-weight: 600;
  }
  .sidebar .portfolio-wrap .portfolio-info p {
  color: rgba(255, 255, 255, 0.979);
  font-size: 14px;
  text-transform: capitalize;
  padding: 0;
  margin: 0;
  font-weight: 600;
  }
  .sidebar .portfolio-wrap .portfolio-links {
  text-align: center;
  z-index: 4;
  }
  .sidebar .portfolio-wrap .portfolio-links a {
  color: rgba(255, 255, 255, 0.979);
  margin: 0 5px 0 0;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
  }
  .sidebar .portfolio-wrap .portfolio-links a:hover {
  color: rgb(255, 255, 255);
  }
  .sidebar .portfolio-wrap:hover::before {
  opacity: 1;
  }
  .sidebar .portfolio-wrap:hover img {
  transform: scale(1.2);
  }
  .sidebar .portfolio-wrap:hover .portfolio-info {
  opacity: 1;
  }
  
  @media (max-width: 768px) {
    .sidebar .portfolio-wrap {
      height: 170px;
      width: 275px;
    }
  }
  
  .sidebar1 .portfolio-item {
  margin-bottom: 75px;
  }
  .sidebar1 #portfolio-flters {
  padding: 0;
  margin: 0 auto 20px auto;
  list-style: none;
  text-align: center;
  }
  .sidebar1 #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 8px 15px 10px 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: #444444;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
  border-radius: 3px;
  }
  .portfolio #portfolio-flters li:hover, .portfolio #portfolio-flters li.filter-active {
  color: #fff;
  background: #e03a3c;
  }
  .sidebar1 #portfolio-flters li:last-child {
  margin-right: 0;
  }
  .sidebar1 .portfolio-wrap {
  transition: 0.3s;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  z-index: 1;
  background: rgba(17, 17, 17, 0.6);
  border: 2px solid #fff;
  }
  .sidebar1 .portfolio-wrap::before {
  content: "";
  background: rgba(4, 26, 59, 0.6);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
  }
  .sidebar1 .portfolio-wrap img {
  transition: all ease-in-out 0.3s;
  }
  .sidebar1 .portfolio-wrap .portfolio-info {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 70px;
  }
  @media (max-width: 768px){
    .sidebar1 .portfolio-wrap .portfolio-info {
      padding: 50px 0;
    }
   
  }
  @media (max-width: 400px) {
    .blog .sidebar .sidebar-item {
      margin-bottom: 30px;
      width: 300px;
      height: 370px;
    }
    .sidebar1 .portfolio-item {
      margin-bottom: 20px;
      width: 350px;
    }
    .sidebar1 .portfolio-wrap .portfolio-info {
      padding: 2px;
    }
  
  }
  .sidebar1 .portfolio-wrap .portfolio-info h4 {
  font-size: 20px;
  color: rgb(255, 255, 255);
  font-weight: 600;
  }
  .sidebar1 .portfolio-wrap .portfolio-info p {
  color: rgba(255, 255, 255, 0.979);
  font-size: 14px;
  text-transform: capitalize;
  padding: 0;
  margin: 0;
  font-weight: 600;
  }
  .sidebar1 .portfolio-wrap .portfolio-links {
  text-align: center;
  z-index: 4;
  }
  .sidebar1 .portfolio-wrap .portfolio-links a {
  color: rgba(255, 255, 255, 0.979);
  margin: 0 5px 0 0;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
  }
  .sidebar1 .portfolio-wrap .portfolio-links a:hover {
  color: rgb(255, 255, 255);
  }
  .sidebar1 .portfolio-wrap:hover::before {
  opacity: 1;
  }
  .sidebar1 .portfolio-wrap:hover img {
  transform: scale(1.2);
  }
  .sidebar1 .portfolio-wrap:hover .portfolio-info {
  opacity: 1;
  }
  
  
  
  
  /*--------------------------------------------------------------
  # Portfolio
  --------------------------------------------------------------*/
  .portfolio .portfolio-item {
    margin-bottom: 30px;
  }
  .portfolio #portfolio-flters {
    padding: 0;
    margin: 0 auto 20px auto;
    list-style: none;
    text-align: center;
  }
  .portfolio #portfolio-flters li {
    cursor: pointer;
    display: inline-block;
    padding: 8px 40px 10px 40px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    color: #444444;
    margin-bottom: 5px;
    transition: all 0.3s ease-in-out;
    border-radius: 3px;
  }
  @media (max-width: 768px) {
    .portfolio #portfolio-flters li {
      padding: 7px 13px 7px 13px;
    }
  }
  .portfolio #portfolio-flters li:hover, .portfolio #portfolio-flters li.filter-active {
    color: #fff;
    background: #0f2b52;
  }
  .portfolio #portfolio-flters li:last-child {
    margin-right: 0;
  }
  .portfolio .portfolio-wrap {
    transition: 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    background: rgba(17, 17, 17, 0.6);
  }
  .portfolio .portfolio-wrap::before {
    content: "";
    background: rgba(4, 26, 59, 0.6);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all ease-in-out 0.3s;
    z-index: 2;
    opacity: 0;
  }
  .portfolio .portfolio-wrap img {
    transition: all ease-in-out 0.3s;
  }
  .portfolio .portfolio-wrap .portfolio-info {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    transition: all ease-in-out 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 20px;
  }
  .portfolio .portfolio-wrap .portfolio-info h4 {
    font-size: 20px;
    color: rgb(255, 255, 255);
    font-weight: 600;
  }
  .portfolio .portfolio-wrap .portfolio-info p {
    color: rgba(255, 255, 255, 0.979);
    font-size: 14px;
    text-transform: capitalize;
    padding: 0;
    margin: 0;
    font-weight: 600;
  }
  .portfolio .portfolio-wrap .portfolio-links {
    text-align: center;
    z-index: 4;
  }
  .portfolio .portfolio-wrap .portfolio-links a {
    color: rgba(255, 255, 255, 0.979);
    margin: 0 5px 0 0;
    font-size: 28px;
    display: inline-block;
    transition: 0.3s;
  }
  .portfolio .portfolio-wrap .portfolio-links a:hover {
    color: rgb(255, 255, 255);
  }
  .portfolio .portfolio-wrap:hover::before {
    opacity: 1;
  }
  .portfolio .portfolio-wrap:hover img {
    transform: scale(1.2);
  }
  .portfolio .portfolio-wrap:hover .portfolio-info {
    opacity: 1;
  }
  /*--------------------------------------------------------------
  # Portfolio
  --------------------------------------------------------------*/
  
  .galary .portfolio-inner-item {
    margin-bottom: 30px;
  }
  .galary #portfolio-flters {
    padding: 0;
    margin: 0 auto 20px auto;
    list-style: none;
    text-align: center;
  }
  .galary #portfolio-flters li {
    cursor: pointer;
    display: inline-block;
    padding: 8px 15px 10px 15px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    color: #444444;
    margin-bottom: 5px;
    transition: all 0.3s ease-in-out;
    border-radius: 3px;
  }
  .portfolio #portfolio-flters li:hover, .portfolio #portfolio-flters li.filter-active {
    color: #fff;
    background: #0f2b52;
  }
  .portfolio #portfolio-flters li:last-child {
    margin-right: 0;
  }
  .galary .portfolio-wrap {
    transition: 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    border: 2px solid #0f2b52;
  
  }
  .galary .portfolio-wrap::before {
    content: "";
  
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all ease-in-out 0.3s;
    z-index: 2;
    opacity: 0;
  }
  .galary .portfolio-wrap img {
   
    transition: all ease-in-out 0.3s;
  }
  .galary .portfolio-wrap .portfolio-info {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    transition: all ease-in-out 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 20px;
  }
  .galary .portfolio-wrap .portfolio-info h4 {
    font-size: 20px;
    color: rgb(255, 255, 255);
    font-weight: 600;
  }
  .galary .portfolio-wrap .portfolio-info p {
    color: rgba(255, 255, 255, 0.979);
    font-size: 14px;
    text-transform: capitalize;
    padding: 0;
    margin: 0;
    font-weight: 600;
  }
  .galary .portfolio-wrap .portfolio-links {
    text-align: center;
    z-index: 4;
  }
  .galary .portfolio-wrap .portfolio-links a {
    color: rgba(255, 255, 255, 0.979);
    margin: 0 5px 0 0;
    font-size: 28px;
    display: inline-block;
    transition: 0.3s;
  }
  .galary .portfolio-wrap .portfolio-links a:hover {
    color: rgb(255, 255, 255);
  }
  .galary .portfolio-wrap:hover::before {
    opacity: 1;
  }
  .galary .portfolio-wrap:hover img {
    transform: scale(1.2);
  }
  .galary .portfolio-wrap:hover .portfolio-info {
    opacity: 1;
  }
  
  /*--------------------------------------------------------------
  # Contact
  --------------------------------------------------------------*/
  
  .contact .info {
    padding: 30px 30px;
    margin: 10px;
    background: #fff;
    text-align: center;
  }
  
  .contact .info i {
    font-size: 48px;
    color: #0f2b52;
    margin-bottom: 15px;
  }
  .contact .info h4 {
    padding: 0;
    margin: 0 0 10px 0;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
  
  }
  .contact .info p {
    font-size: 15px;
    padding: 0 5px;
  }
  .contact .info1 {
    padding: 20px 10px 5px 10px;
    margin: 15px;
    background: #0f2b52;
    text-align: center;
  }
  .contact .info1 i {
    font-size: 48px;
    color: rgb(243, 243, 243);
    margin-bottom: 15px;
  }
  .contact .info1 h4 {
    padding: 0;
    margin: 0 0 10px 0;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    color: rgb(243, 243, 243);
  
  }
  .contact .info1 p {
    font-size: 15px;
    color: #fff;
    font-weight: 300;
  }
  @media (max-width: 768px) {
    .contact .info {
      padding: 10px;
      margin: 5px;
      border-radius: 5px;
    }
    .contact .info i {
      font-size: 35px;
      margin-bottom: 12px;
    }
    .contact .info p {
      font-size: 10.5px;
      padding: 0 3px;
    }
    .contact .info h4 {
      font-size: 14px;
    }
    .contact .info1 {
      margin: 5px;
      border-radius: 5px;
    }
    .contact .info1 i {
      font-size: 35px;
      margin-bottom: 12px;
    }
    .contact .info1 p {
      font-size: 10.5px;
      padding: 0 3px;
    }
    .contact .info1 h4 {
      font-size: 14px;
    }
  }
  .contact .php-email-form {
    width: 100%;
    padding: 0 30px;
  }
  .contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #0f2b52;
    text-align: left;
    padding: 15px;
    font-weight: 600;
  }
  .contact .php-email-form .error-message br + br {
    margin-top: 25px;
  }
  .contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }
  .contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
  }
  .contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
  }
  .contact .php-email-form label {
  
    margin-bottom: 5px;
    color: #777777;
  }
  .contact .php-email-form input, .contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    border-radius: 4px;
  }
  .contact .php-email-form input:focus, .contact .php-email-form textarea:focus {
    border-color: #0f2b52;
  }
  .contact .php-email-form input {
    padding: 10px 15px;
  }
  .contact .php-email-form textarea {
    padding: 12px 15px;
  }
  .contact .php-email-form button[type=submit] {
    background: #fff;
    border: 2px solid #0f2b52;
    padding: 12px 35px;
    transition: 0.4s;
    background: #0f2b52;
    color: #fff;
    border-radius: 4px;
  }
  .contact .php-email-form button[type=submit]:hover {
    background: #0f2b52;
  }
  @media (max-width: 1024px) {
    .contact .php-email-form {
      padding: 30px 15px 15px 15px;
    }
  }
  @media (max-width: 768px) {
    .contact .php-email-form {
      padding: 15px 0 0 0;
    }
  }
  @-webkit-keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  
  /*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
  #footer {
    color: #fff;
    font-size: 14px;
   /*  background: #0d254d ; #0e1c46*/
    background: #0d254d; 
    
  }
  #footer .footer-top {
    padding: 60px 0 30px 0;
     /*  background: #0d254d ; #0e1c46*/
     background: #0d254d;
  }
  @media (max-width: 768px) {
    #footer .footer-top {
      padding: 20px 0 30px 0;
    }
  }
  #footer .footer-top .footer-contact {
    margin-bottom: 30px;
  }
  #footer .footer-top .footer-contact h3 {
    font-size: 26px;
    line-height: 1;
    font-weight: 700;
  }
  #footer .footer-top .footer-contact h3 span {
    color:  #1fc1c3;
  }
  #footer .footer-content h3 span {
    color:  #1fc1c3;
  }
  #footer .copyright span {
    color:  #1fc1c3;
  }
  #footer .footer-top .footer-contact p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
  
  }
  #footer .footer-top h4 {
    font-size: 16px;
    font-weight: bold;
    position: relative;
    padding-bottom: 12px;
    color: #1fc1c3;
  }
  #footer .footer-top h4::after {
    content: "";
    position: absolute;
    display: block;
    width: 20px;
    height: 2px;
    background:  #1fc1c3;
    bottom: 0;
    left: 0;
  }
  #footer .footer-top .footer-links {
    margin-bottom: 30px;
  }
  #footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  #footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #1fc1c3;
    font-size: 18px;
    line-height: 1;
  }
  #footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
  @media (max-width: 768px) {
    #footer .footer-top .footer-links ul li {
      padding: 5px 0;
    }
  }
  #footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
  }
  #footer .footer-top .footer-links ul a {
    color: #aaaaaa;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
    text-decoration: none;
  }
  #footer .footer-top .footer-links ul a:hover {
    text-decoration: none;
    color: #fff;
  }
  #footer .footer-newsletter {
    font-size: 15px;
  }
  #footer .footer-newsletter h4 {
    font-size: 16px;
    font-weight: bold;
    position: relative;
    padding-bottom: 12px;
  }
  #footer .footer-newsletter form {
    margin-top: 30px;
    background: #fff;
    padding: 5px 10px;
    position: relative;
    border-radius: 4px;
    text-align: left;
  }
  #footer .footer-newsletter form input[type=email] {
    border: 0;
    padding: 4px 8px;
    width: calc(100% - 100px);
  }
  #footer .footer-newsletter form input[type=submit] {
    position: absolute;
    top: 0;
    right: -1px;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px;
    background: #e03a3c;
    color: #fff;
    transition: 0.3s;
    border-radius: 0 4px 4px 0;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }
  #footer .credits {
    padding-top: 5px;
    font-size: 13px;
  }
  #footer .social-links a {
    font-size: 18px;
    display: inline-block;
    color:  #1fc1c3;
    line-height: 1;
    padding: 8px 0;
    border-radius: 4px;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: all 0.3s;
    border: 2px solid  #1fc1c3;
    
  }
  #footer .social-links a:hover {
    background:  #1fc1c3;
    color: #0a253b;
    text-decoration: none;
  }

  #footer .social-links a:hover svg {
    transition: 0.5s all;
    color: #0a253b;
  }
  
  /* new contact form */
  
      .form {
      width: 100%;
      max-width: 820px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
      z-index: 1000;
      overflow: hidden;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
    
    .contact-form {
      background-color: #0f2b52;
      position: relative;
    }
    
    .contact-form:before {
      content: "";
      position: absolute;
      width: 26px;
      height: 26px;
      background-color: #0f2b52;
      transform: rotate(45deg);
      top: 50px;
      left: -13px;
    }
    
    form {
      padding: 2.3rem 2.2rem;
      z-index: 10;
      overflow: hidden;
      position: relative;
    }
    
    .title {
      color: #fff;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 1;
      margin-bottom: 0.7rem;
    }
    
    .input-container {
      position: relative;
      margin: 1rem 0;
    }
    
    .input {
      width: 100%;
      outline: none;
      border: 2px solid #fafafa;
      background: none;
      padding: 0.6rem 1.2rem;
      color: #fff;
      font-weight: 500;
      font-size: 0.95rem;
      letter-spacing: 0.5px;
      border-radius: 5px;
      transition: 0.3s;
      color-scheme: dark;
    }
    .input-container select option {
      background: #0f2b52;
    }
    
    textarea.input {
      padding: 0.8rem 1.2rem;
      min-height: 150px;
      border-radius: 5px;
      resize: none;
      overflow-y: auto;
    }
    
    .input-container label {
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
      padding: 0 0.4rem;
      color: #fafafa;
      font-size: 0.9rem;
      font-weight: 400;
      pointer-events: none;
      z-index: 1000;
      transition: 0.5s;
    }
    
    .input-container.textarea label {
      top: 1rem;
      transform: translateY(0);
    }

    .pointer {
      cursor: pointer;
    }
    
    .btn {
      padding: 0.6rem 1.3rem;
      background-color: #fff;
      border: 2px solid #fafafa;
      font-size: 0.95rem;
      color: #0f2b52;
      line-height: 1;
      border-radius: 5px;
      outline: none;
      cursor: pointer;
      transition: 0.3s;
      margin: 0;
      width: 100%;
    }
    
    .btn:hover {
      background-color: transparent;
      color: #fff;
    }
    
    .input-container span {
      position: absolute;
      top: 0;
      left: 25px;
      transform: translateY(-50%);
      font-size: 0.8rem;
      padding: 0 0.4rem;
      color: transparent;
      pointer-events: none;
      z-index: 500;
    }
    
    .input-container span:before,
    .input-container span:after {
      content: "";
      position: absolute;
      width: 10%;
      opacity: 0;
      transition: 0.3s;
      height: 5px;
      background-color: #0f2b52;
      top: 50%;
      transform: translateY(-50%);
    }
    
    .input-container span:before {
      left: 50%;
    }
    
    .input-container span:after {
      right: 50%;
    }
    
    .input-container.focus label {
      top: 0;
      transform: translateY(-50%);
      left: 25px;
      font-size: 0.8rem;
    }
    
    .input-container.focus span:before,
    .input-container.focus span:after {
      width: 50%;
      opacity: 1;
    }
    
    .contact-info {
      padding: 2.3rem 2.2rem;
      position: relative;
    }
    
    .contact-info .title {
      color: #0f2b52;
    }
    
    .text {
      color: #333;
      margin: 1.5rem 0 2rem 0;
    }
    
    .information {
      display: flex;
      color: #555;
      margin: 0.7rem 0;
      align-items: center;
      font-size: 0.95rem;
    }
    
    
    .contact-info:before {
      content: "";
      position: absolute;
      width: 110px;
      height: 100px;
      border: 22px solid black;
      border-radius: 50%;
      bottom: -77px;
      right: 50px;
      opacity: 0.3;
    }
    
    
    @media (max-width: 850px) {
      .form {
        grid-template-columns: 1fr;
      }
    
      .contact-info:before {
        bottom: initial;
        top: -75px;
        right: 65px;
        transform: scale(0.95);
      }
    
      .contact-form:before {
        top: -13px;
        left: initial;
        right: 70px;
      }
    
      .text {
        margin: 1rem 0 1.5rem 0;
      }
    }
    
    @media (max-width: 480px) {
     
    
      .contact-info:before {
        display: none;
      }
    
      form,
      .contact-info {
        padding: 1.7rem 1.6rem;
      }
    
      .text,
      .information{
        font-size: 0.8rem;
      }
    
      .title {
        font-size: 1.15rem;
      }
    
      .icon {
        width: 23px;
      }
    
      .input {
        padding: 0.45rem 1.2rem;
      }
    
      .btn {
        padding: 0.45rem 1.2rem;
      }
    }
  /* new contact form end*/
  .cookie {
    margin: 10;
    padding: 50;
    box-sizing: border-box;
  }
  .cookie .wrapper{
    position: absolute;
    bottom: 30px;
    left: 30px;
    max-width: 365px;
    background: #0a2540;
    padding: 25px 25px 30px 25px;
    border-radius: 15px;
    box-shadow: 1px 7px 14px -5px rgba(0,0,0,0.15);
    text-align: center;
  }
  .cookie .wrapper.hide{
    opacity: 0;
    pointer-events: none;
    transform: scale(0.8);
    transition: all 0.3s ease;
  }
  .cookie ::selection{
    color: #fff;
    background: rgb(31, 206, 230);
  }
  .cookie .wrapper img{
    max-width: 90px;
  }
  .cookie .content header{
    font-size: 25px;
    font-weight: 600;
  }
  .cookie .content{
    margin-top: 10px;
  }
  .cookie .content p{
    color: #858585;
    margin: 5px 0 20px 0;
  }
  .cookie .content .buttons{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cookie .buttons button{
    padding: 10px 20px;
    border: none;
    outline: none;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
    background: #FCBA7F;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .cookie .buttons button:hover{
    transform: scale(0.97);
  }
  .cookie .buttons .item{
    margin: 0 10px;
  }
  .cookie .buttons a{
    color: #FCBA7F;
  }
  
  /*--------------------------------------------------------------
  # Featured Services
  --------------------------------------------------------------*/
  .career-benefits {
  }
  .cta .cta-btn-career {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 35px;
    border-radius: 4px;
    transition: 0.5s;
    margin-top: 20px;
    background: #0f2b52;
    border: 2px solid #0f2b52;
    color: #fff;
  }
  .cta .cta-btn-career:hover {
    background: none;
    color: #0f2b52;
  }
  /*.career-benefits .icon-box {
    padding: 30px;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
    z-index: 1;
    border: 2px solid rgba(114, 111, 111, 0.884);
    text-align: center;
  }
  .career-benefits .icon-box::before {
    content: "";
    position: absolute;
   background: #cbe0fb; 
    right: 0;
    left: 0;
    bottom: 0;
    top: 100%;
    transition: all 0.3s;
    z-index: -1;
  }
  .career-benefits .icon-box:hover::before {
   background: #0f2b52;
    top: 0;
    border-radius: 0px;
  }
  .career-benefits .icon {
    margin-bottom: 15px;
  }
  .career-benefits .icon i {
    font-size: 48px;
    line-height: 1;
    color: rgba(255, 255, 255, 0.699);
    transition: all 0.3s ease-in-out;
  }
  .career-benefits .title {
    font-weight: 800;
    margin-bottom: 15px;
    font-size: 20px;
  }
  .career-benefits .title a {
    font-size: 25px;
    color: #fff;
  }
  .career-benefits .description {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 0;
    color: rgba(255, 255, 255, 0.699);
  }
  .career-benefits .icon-box:hover .title a, .career-benefits .icon-box:hover .description {
    color: #fff;
  }
  .career-benefits .icon-box:hover .icon i {
    color: #fff;
  }*/

  .career-bg h1, .sharceus {
    font-family: 'Lora', serif;
  }


  .clients-inner {
    background: #fff; 
    padding: 50px; 
    border-radius: 10px;
  }
  @media (max-width: 768px) {
    .clients-inner {
      padding: 21px;
}
  }
  .founder-about .section-title {
    background: rgb(225, 231, 243);
    padding: 30px 50px; 
    margin: 15px 0 15px -10px
  }
  .founder {
    padding: 0 35px; 
    background: #0f2b52;
  }
 @media (max-width: 768px) {
   .founder-img {
     width: 100%;
     padding:0 35px 7px;
   }
   .founder-about .section-title {
     padding: 13px 8px;
     margin: 11px 0 -15px 2px;
   }
   .founder {
     padding: 0 20px;
   }
 }
 .zoom {
  transition: all ease-in-out 0.3s;
}
.zoom:hover {
  transform: scale(1.09);
}
.career-benefits .icon-box {
  text-align: center;
  padding: 20px 20px 20px 20px;
  transition: all ease-in-out 0.3s;
  background: rgba(0, 0, 0, 0.582);
  border-radius: 20px;
  border: 2px solid rgb(128, 126, 126);
 
}
.career-benefits .icon-box .icon {
  margin: 0 auto;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease-in-out 0.3s;
  position: relative;
}
@media (max-width: 768px) {
  .career-benefits .icon-box .icon {
    height: 50px;
  }
}
.career-benefits .icon-box .icon i {
  font-size: 36px;
  transition: 0.5s;
  position: relative;
 
}
.career-benefits .icon-box .icon svg {
  position: absolute;
  top: 0;
  left: 0;
}
.career-benefits .icon-box .icon svg path {
  transition: 0.5s;
  fill: #fff;
}
.career-benefits .icon-box h4 {
  font-weight: 600;
  margin: 5px 0 15px 0;
  font-size: 20px;
  color: #fff;
}
.career-benefits .icon-box h4 a {
  color: #fff;
  transition: ease-in-out 0.3s;
}
.career-benefits .icon-box p {
  line-height: 26px;
  font-size: 17px;
  margin-bottom: 0;
  color: rgb(197, 193, 193);
}
.career-benefits .icon-box:hover {
  border-color: #fff;

}
.career-benefits .iconbox-orange i {
  color: rgb(184, 182, 182);
}
.career-benefits .icon-box:hover i {
  color: #fff;
}
.profile-card-4 {
  max-width: 500px;
  background-color: #0f2b52;
  border-radius: 5px; 
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  cursor: pointer;
}
.profile-card-4 img {
  
  max-height: 270px;
  transition: all 0.25s linear;
}
.profile-card-4 .profile-content {
  position: relative;
  height: 350px;
  padding: 15px;
  background-color: #0f2b52;
}
.profile-card-4 .profile-name {
  font-weight: bold;
  position: absolute;
  left: 0px;
  right: 0px;
  top: -70px;
  color: #0f2b52;
  font-size: 17px;
}
.profile-card-4 .profile-name p {
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 1.5px;
}
.profile-card-4 .profile-description {
  color: #fff;
  font-size: 18px;
  padding: 10px;
}
@media (max-width: 768px) {
  .profile-card-4 .profile-description {
    text-align: left;
  }
}
.profile-card-4 .profile-overview {
  padding: 15px 0px;
}

.profile-card-4 .profile-overview p {
  font-size: 10px;
  font-weight: 600;
  color: #fff;
}

.profile-card-4 .profile-overview h4 {
  color: #273751;
  font-weight: bold;
}

.profile-card-4 .profile-content::before {
  content: "";
  position: absolute;
  height: 20px;
  top: -10px;
  left: 0px;
  right: 0px;
  background-color: #0f2b52;
  z-index: 0;
  transform: skewY(3deg);
}

.profile-card-4:hover img {
  transform: rotate(5deg) scale(1.1, 1.1);
  filter: brightness(110%);
}

.slick-dots {
  bottom: 15px !important;
}

.slick-dots li button:before {
  color: #9CC3D5FF !important;
  font-size: 10px !important;
  opacity: 1 !important;
}

.slick-dots li.slick-active button:before {
  color: #0f2b52 !important;
  font-size: 10px !important;
  opacity: 1 !important;
}

.homePartner img{
  width: 100%;
}
.section{
  background:  #a7abac;
  padding: 30px 30px;
  position: relative;
  display: flex;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.service-block1 {
  box-shadow:  0 0 0 2px rgb(255, 255, 255),
}

img {
  border-image-width: 100%;
  height: 100%;
}

.popular-service {
  box-shadow:  0 0 0 2px rgb(31, 12, 99),
}

.form {
  margin-right: 40px;
}

.cont-background {
  background-color:#9CC3D5FF;
  border-image-width: 100%;
  min-height: 100px;
}
 
.section{
  background-color:white;
  z-index:+ 10;
}

.customDatePicker input {
  padding: 15px 10px;
  border-radius: 5px;
  border: 1px solid #dee2e6;
  color: #585c5e;
  transition: 0.2s all;
}

.customDatePicker input:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
}

.input-group .input-group-text {
  background-color: white;
}

.input-group input::placeholder {
  color:rgb(175, 175, 175)
}

.toggle {
  cursor: pointer;
}

.errorText {
  font-size: 12px;
  color: red;
}

.errorInputField {
  border: 1px solid red !important;
}

.errorCheckboxField > span {
  border: 1px solid red !important;
}

.errorCheckboxField > input {
  border: 1px solid red !important;
  border-left: none !important;
}

.errorSelectField > div {
  border: 1px solid red !important;
}

.imgBordered {
  border-radius: 10px;
}

.portfolio-item .member span {
  color: #0f2b52;
}
.portfolio-item .member {
 position: relative;
 background: #d3d7dd;
 box-shadow: 0px 2px 15px rgba(44, 73, 100, 0.08);
 padding: 30px;
 border-radius: 10px;
}
.portfolio-item .member .pic {
 overflow: hidden;
 width: 150px;
 height: 180px;
 border-radius: 10px;
}
.portfolio-item .member .pic img {
 transition: ease-in-out 0.3s;
}
.portfolio-item .member:hover img {
 transform: scale(1.1);
}
.portfolio-item .member .member-info {
 padding-left: 30px;
}
.portfolio-item .member h4 {
 font-weight: 700;
 margin-bottom: 5px;
 font-size: 20px;
 color: #0f2b52;
}
.portfolio-item .member span {
 display: block;
 font-size: 15px;
 padding-bottom: 10px;
 position: relative;
 font-weight: 500;
}
.portfolio-item .member span::after {
 content: "";
 position: absolute;
 display: block;
 width: 50px;
 height: 1px;
 background: #03284b;
 bottom: 0;
 left: 0;
}
.portfolio-item .member p {
 margin: 10px 0 0 0;
 font-size: 14px;
 color: #0f2b52;
}
.portfolio-item .member .social {
 margin-top: 12px;
 display: flex;
 align-items: center;
 justify-content: flex-start;
}
.portfolio-item .member .social a {
 transition: ease-in-out 0.3s;
 display: flex;
 align-items: center;
 justify-content: center;
 border-radius: 50px;
 width: 32px;
 height: 32px;
 background: #a0bcd5;
}
.portfolio-item .member .social a i {
 color: #fff;
 font-size: 16px;
 margin: 0 2px;
}
.portfolio-item .member .social a:hover {
 background: #1977cc;
}
.portfolio-item .member .social a + a {
 margin-left: 8px;
}

.customAwards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-column-gap: 1rem;
  grid-row-gap: 2rem;
}
.customAwards img {
  width: 100%;
  object-fit: cover;
  border-radius: 1rem;
  margin-bottom: 0.2rem;
}
.customAwards h2 {
  font-size: 0.8rem;
}

.customAwards button {
  margin-right: 2rem;
  margin-bottom: 2rem;
  min-width: 5rem;
  padding: 0.5rem 1rem;
  border: none;
  background: white;
  color: rgb(65, 98, 168);
  border-radius: 1rem;
  border: 2px solid rgb(65, 98, 168);
  font-weight: bold;
  cursor: pointer;
}
.customAwards button.active {
  background-color: rgb(65, 98, 168);
  color: white;
}

.customClass {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #ffffffc7;
  z-index: 11;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1400px) {
  .customHomeBox h5 {
    font-size: 1rem;
  }
}

@media (max-width: 1200px) {
  .customHomeBox h5 {
    font-size: 0.85rem;
  }

  .display-4 b {
    font-size: 2rem;
    line-height: 0.8;
  }
}

@media (max-width: 800px) {
  .video-background-content .btn-get-started {
    font-size: 12px;
    padding: 7px 20px;
    margin-top: 15px;
  }
}

@media (max-width: 768px) {
  .customBox {
    transform: translate(-50%, -60px);
  }
}

@media (max-width: 600px) {
  .display-4 b {
    font-size: 1.25rem;
    line-height: 0.8;
  }
}


.vacancySection hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0.5px solid grey;
}
.vacancySection #portfolio .img-fluid {
  width: calc(100%);
  height: 30vh;
  z-index: -1;
  position: relative;
  padding: 1em;
}
.vacancySection .vacancy-list{
  cursor: pointer;
  margin-bottom: 20px;
}
.vacancySection span.hightlight{
  background: yellow;
}
.vacancySection .view {
  color: #fff;
  background: #0f2b52;
  border: 2px solid #0f2b52;
  transition: .5s;
}
.vacancySection .view:hover {
  color: #0f2b52;
  background: transparent;
}
.vacancySection .btn-share {
  color: #0f2b52;
  background: none;
  border: none;
  transition: .5s;
}
.vacancySection .btn-share:hover {
  color: #fff;
  background: #0f2b52;
  border-radius: 50px;
}
.vacancySection .truncate {
 overflow: hidden;
 text-overflow: ellipsis;
 display: -webkit-box;
 -webkit-line-clamp: 3; 
 -webkit-box-orient: vertical;
  font-size: small;
  color: #000000cf;
}
.vacancySection .given {
  display: inline;
}
@media (max-width: 768px) {
  .vacancySection .given {
      float: left;
  }
}

.wizLogo {
  width: 400px;
  height: auto;
  padding: 10px 0 40px 0;
}


.menuToggle {
  display: block;
  position: relative;
  top: 50px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
  a {
    text-decoration: none;
    color: #232323;
    transition: all 0.3s ease;
    &:hover {
      color: tomato;
    }
  }
  input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    cursor: pointer;
    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */
    -webkit-touch-callout: none;
  }
  span {
    position: relative;
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    background: #cdcdcd;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                opacity 0.55s ease;
    &:first-child {
      transform-origin: 0% 0%;
    }
    &:nth-last-child(2) {
      transform-origin: 0% 100%;
    }
  }
}

.menuToggle {
  input {
    &:checked ~ span {
      opacity: 1;
      transform: rotate(45deg) translate(-2px, -1px);
      background: #232323;
      &:nth-last-child(3) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
      }
      &:nth-last-child(2) {
        transform: rotate(-45deg) translate(0, -1px);
      }
    }
    &:checked ~ .menuItem {
      transform: none;
    }
    
  }
}

.menuItem {
  position: absolute;
  width: 300px;
  padding: 50px;
  padding-top: 125px;
  background: #ededed;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  li {
    padding: 10px 0;
    font-size: 22px;
  }
}
.menuToggle {
  display: block;
  position: relative;
  top: 20px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
  transition: 0.5s all ease;
}
.menuToggle.header-scrolled {
  top: 15px;
}
.menuToggle a {
  text-decoration: none;
  color: #a0bcd5;
  transition: all 0.3s ease;
}
.menuToggle a:hover {
  color: tomato;
}
.menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  cursor: pointer;
  opacity: 0;
  /* hide this */
  z-index: 2;
  /* and place it over the hamburger */
  -webkit-touch-callout: none;
}
.menuToggle span {
  position: relative;
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: #232323;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}
.menuToggle span:first-child {
  transform-origin: 0% 0%;
}
.menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

.menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #839db6;
}
.menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}
.menuToggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}
.menuToggle input:checked ~ .menuItem {
  transform: none;
}

.menuItem {
  position: absolute;
  width: 300px;
  padding: 50px;
  padding-top: 125px;
  background: #ededed;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}
.menuItem li {
  font-size: 22px;
}

.menu--right .menuToggle {
  position: fixed;
  right: 0;
}
.menu--right .menuToggle input {
  right: 50px;
}
.menu--right .menuToggle span {
  right: 50px;
}
.menu--right .menuItem {
  right: 0;
  margin: -100px 0 0 0;
  transform: translate(100%, 0);
  height: 100vh;
  padding-top:100px;
  background-color: #212529;
}
.menu--right .menuItem li a:hover {
  padding-left: 25px;
  font-size: 20px;
}

@keyframes text-slide-in {
  0% {
    -webkit-transform: translateX(100px);
    -moz-transform: translateX(100px);
    -ms-transform: translateX(100px);
    -o-transform: translateX(100px);
    transform: translateX(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}